
declare global {
	type Timeout = ReturnType<typeof setTimeout>
	type $ = JQuery<HTMLElement>
	interface JQuery {
		tableHeaders(this: $, name: string[]): $
		doubleScroll(this: $, userOptions?: doubleScrollParams): $
	}
}

$.fn.tableHeaders = function(names) {
	const $table = $(this)
	$table.find('thead').remove()
	const html = names.map(name => `<th>${name}</th>`).join('')
	$table.prepend(`<thead><tr>${html}</tr></thead>`)
	return $table
}

type doubleScrollParams = {
	$insertBefore?: $
	contentElement?: $
	scrollCss?: any
	contentCss?: any
	onlyIfScroll?: boolean
	resetOnWindowResize?: boolean
	timeToWaitForResize?: number
}
$.fn.doubleScroll = function(userOptions) {
	//Merge user options with default options
	const options: doubleScrollParams = {
		$insertBefore: undefined, // Insert second scroll bar before this element, if not specified main element will be used

		contentElement: undefined, // Widest element, if not specified first child element will be used
		scrollCss: {                
			'overflow-x': 'auto',
			'overflow-y': 'hidden',
			'height': '20px'
		},
		contentCss: {
			'overflow-x': 'auto',
			'overflow-y': 'hidden'
		},
		onlyIfScroll: true, // top scrollbar is not shown if the bottom one is not present
		resetOnWindowResize: true, // recompute the top ScrollBar requirements when the window is resized
		timeToWaitForResize: 30, // wait for the last update event (usefull when browser fire resize event constantly during ressing)

		...userOptions,
	}

	const internalOptions = {
		topScrollBarMarkup: '<div class="doubleScroll-scroll-wrapper"><div class="doubleScroll-scroll"></div></div>',
		topScrollBarInnerSelector: '.doubleScroll-scroll',
	}

	const showScrollBar = ($self: $, $topScrollBar: $ | null = null) => {

		// add div that will act as an upper scroll only if not already added to the DOM
		if (!$topScrollBar) {
			const $insertBefore = options.$insertBefore || $self

			// creating the scrollbar
			// added before in the DOM
			$topScrollBar = $(internalOptions.topScrollBarMarkup)
			$insertBefore.before($topScrollBar)

			// apply the css
			$topScrollBar.css(options.scrollCss)
			$(internalOptions.topScrollBarInnerSelector).css({height: '20px'})
			$self.css(options.contentCss)

			let scrolling = false

			// bind upper scroll to bottom scroll
			$topScrollBar.on('scroll.doubleScroll', () => {
				if (scrolling) {
					scrolling = false
					return
				}
				scrolling = true
				$self.scrollLeft($topScrollBar!.scrollLeft() || 0)
			})

			// bind bottom scroll to upper scroll
			$self.on('scroll.doubleScroll', () => {
				if (scrolling) {
					scrolling = false
					return
				}
				scrolling = true
				$topScrollBar!.scrollLeft($self.scrollLeft() || 0)
			})
		}

		const hideScroll = options.onlyIfScroll && $self.get(0)!.scrollWidth <= Math.round($self.width() || 0)
		if (hideScroll) $topScrollBar.hide()
		else $topScrollBar.show()
		
		// find the content element (should be the widest one)	
		let $contentElement: $
		if (options.contentElement !== undefined && $self.find(options.contentElement).length !== 0) {
			$contentElement = $self.find(options.contentElement)
		}
		else {
			$contentElement = $self.find('>:first-child')
		}
		
		// set the width of the wrappers
		$topScrollBar.add($topScrollBar.find(internalOptions.topScrollBarInnerSelector)).width($contentElement.outerWidth() || 0)
		$topScrollBar.width($self.width() || 0)
		$topScrollBar.scrollLeft($self.scrollLeft() || 0)

		return $topScrollBar
	}

	return this.each((_, element) => {
		
		const $self = $(element)
		
		const $topScrollBar = showScrollBar($self)
		
		// bind the resize handler 
		// do it once
		if (options.resetOnWindowResize) {
			let timeout: Timeout | null = null
			$(window).on('resize.doubleScroll', () => {
				// adding/removing/replacing the scrollbar might resize the window
				// so the resizing flag will avoid the infinite loop here...
				if (timeout) clearTimeout(timeout)
				timeout = setTimeout(() => showScrollBar($self, $topScrollBar), options.timeToWaitForResize)
			})
		}
	})
}


/** Fetch post wrapper that works with PHP $_POST and throws responses that don't have {rc: 'OK'} */
export const fetchPost = async (url: string, postData?: object | FormData) => {
    let requestBody: RequestInit['body'] = null;
    const headers: RequestInit['headers'] = {
        Accept: 'application/json',
		'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    };
    if (postData instanceof FormData) {
        requestBody = postData;
    } else if (postData !== undefined) {
        requestBody = JSON.stringify(postData);
        headers['Content-Type'] = 'application/json';
    }
    const response = await fetch(url, {
        method: 'POST',
        headers,
        body: requestBody,
    });
    const json = await response.json();
    // if (json.hasOwnProperty('rc') && json.rc !== 'OK') throw json;
    return json;
};


type setCollapseParams = {
	$btn: $
	$target: $
	duration?: number
}
export const setCollapse = ({$btn, $target, duration = 350}: setCollapseParams) => {
	const show = () => {
		$target.slideDown(duration, () => $btn.find('svg').addClass('fa-caret-up').removeClass('fa-caret-down'))
	}
	const hide = () => {
		$target.slideUp(duration, () => $btn.find('svg').addClass('fa-caret-down').removeClass('fa-caret-up'))
	}
	$btn.on('click', () => {
		if ($target.css('display') === 'none') show()
		else hide()
	})
}

type BootstrapFlavor = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
export const displayNotification = (msg: string, background: BootstrapFlavor = 'success') => {
	const $notifications = $('.notifications').length ? $('.notifications') : $('<div class="notifications"></div>').prependTo($('body'))
	const $notification = $(`
	  <div class="notification alert alert-dismissible alert-${background}" role="alert">
		<span>${msg}</span>
		<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
	  </div>
	`)
	$notification.appendTo($notifications)
	setTimeout(() => $notification.fadeOut(500, () => $notification.remove()), 2000)
}

export const setAll = (obj: object, val) => Object.keys(obj).forEach(k => obj[k] = val);

export const post = (path: string, parameters: object | null, target: string = null) => {
    const $form = $('<form></form>')

    $form.attr('method', 'post')
    $form.attr('action', path)
	if (target != null) $form.attr('target', target)

	if (parameters !== null) {
		for (const [key, value] of Object.entries(parameters)) {
			const $input = $('<input></input>')
	
			$input.attr('type', 'hidden')
			$input.attr('name', key)
			$input.attr('value', value)
	
			$form.append($input)
		}
	}

    $(document.body).append($form)
    $form.trigger('submit')
}

export const print_this = (config) => {
	try {
		let printwin = window.open('');
		printwin.document.body.style.pointerEvents = "none";

		let $head = $('head').clone();
		$head.find('title').html(config.title + ' | KidsVax');
		setTimeout(function() {
			printwin.document.write($head.html());
			let $content = config.callback();
			printwin.document.write($content.html());
	
			setTimeout(function(){
				printwin.print();
			},250);
		}, 50);
		
	} catch (e) {
		alert(e);
		console.log('print_this',e);
	}
}

export const htmlEsc = (text: string) => {
	const span = document.createElement('span');
	span.textContent = text;
	return span.innerHTML;
};

export const convertPennies = (pennies: number) => {
	(pennies / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});	
} 

export const setTimeoutPromise = (duration: number) => new Promise((resolve) => setTimeout(resolve, duration))

