import '/resources/sass/darkly.scss'
//import '/resources/sass/flatly.scss'

import '/resources/core/chosen.css'
import '/resources/core/component-chosen.min.css'
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

import '/resources/core/chosen-sprite.png'
import '/resources/core/chosen-sprite@2x.png'

// @ts-ignore
import $ from 'expose-loader?exposes=$,jQuery!jquery'

import 'datatables.net-bs5'
import 'datatables.net-buttons-bs5'

import 'bootstrap'
import '/resources/core/chosen.jquery.js'

import { displayNotification, fetchPost, setCollapse, post, print_this, htmlEsc, convertPennies, setTimeoutPromise} from './util'
import { MFA_METHOD_EMAIL, MFA_METHOD_SMS, MFA_METHOD_APP, UPLOAD_IMG } from './constants'

const datatablesButtonDom = `
	<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-8 d-flex justify-content-end'f<'ms-2'B>>>
	<'row'<'col-sm-12'tr>>
	<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>
`

const spinEverything = false

const initProfiles = () => {
	const getProfileFields = (): {[col: string]: 1 | 0} => {
		const fields = {}
		columnsInfo.forEach(({data, fieldId}) => {
			if (fieldId) fields[data] = $('#' + fieldId).is(':checked') ? 1 : 0
		})
		return fields
	}
	
	const getProfilesFilters = () => ({
		site: $('#profileFilterSite').val(),
		status: $('#profileFilterStatus').val(),
		state: $('#profileFilterState').val(),
	})

	const profileFieldsTypeToggle = ({type, checked}) => {
		const columns = columnsInfo.filter((col) => col.tableType === type)
		const ids = columns.map((col) => '#' + col.fieldId).join(',')
		$(ids).prop('checked', checked)
	}

	$('#profileFieldClient').on('change', () => {
		profileFieldsTypeToggle({type: 'client', checked: $('#profileFieldClient').is(':checked')})
	})
	$('#profileFieldAdmin').on('change', () => {
		profileFieldsTypeToggle({type: 'admin', checked: $('#profileFieldAdmin').is(':checked')})
	})
	$('#profileFieldExec').on('change', () => {
		profileFieldsTypeToggle({type: 'exec', checked: $('#profileFieldExec').is(':checked')})
	})

	setCollapse({$btn: $('#profilesFiltersCollapse'), $target: $('#profilesFilters')})
	setCollapse({$btn: $('#profilesFieldsCollapse'), $target: $('#profilesFields')})

	//Init table
	const columnsInfo = [
		{
			data: 'client_name',
			header: 'Name',
		},
		{
			data: 'status_pretty',
			header: 'Status',
			fieldId: 'profileFieldStatus',
			tableType: 'client',
		},
		{
			data: 'client_fein',
			header: 'FEIN',
			fieldId: 'profileFieldFein',
			tableType: 'client',
		},
		{
			data: 'site_name',
			header: 'Site',
			fieldId: 'profileFieldSite',
			tableType: 'client',
		},
		{
			data: 'client_moddate_pretty',
			header: 'Modified',
			fieldId: 'profileFieldModified',
			tableType: 'client',
		},
		{
			data: 'client_address1',
			header: 'Address 1',
			fieldId: 'profileFieldAddress1',
			tableType: 'client',
		},
		{
			data: 'client_address2',
			header: 'Address 2',
			fieldId: 'profileFieldAddress2',
			tableType: 'client',
		},
		{
			data: 'client_city',
			header: 'City',
			fieldId: 'profileFieldCity',
			tableType: 'client',
		},
		{
			data: 'client_state',
			header: 'State',
			fieldId: 'profileFieldState',
			tableType: 'client',
		},
		{
			data: 'client_zip',
			header: 'Zip',
			fieldId: 'profileFieldZip',
			tableType: 'client',
		},
		{
			data: 'client_naic',
			header: 'NAIC',
			fieldId: 'profileFieldNaic',
			tableType: 'client',
		},
		{
			data: 'client_group',
			header: 'Group',
			fieldId: 'profileFieldGroup',
			tableType: 'client',
		},

		{
			data: 'admin_title',
			header: 'Admin Title',
			fieldId: 'profileFieldAdminTitle',
			tableType: 'admin',
		},
		{
			data: 'admin_first',
			header: 'Admin First Name',
			fieldId: 'profileFieldAdminFirst',
			tableType: 'admin',
		},
		{
			data: 'admin_last',
			header: 'Admin Last Name',
			fieldId: 'profileFieldAdminLast',
			tableType: 'admin',
		},
		{
			data: 'admin_email',
			header: 'Admin Email',
			fieldId: 'profileFieldAdminEmail',
			tableType: 'admin',
		},
		{
			data: 'admin_position',
			header: 'Admin Position',
			fieldId: 'profileFieldAdminPosition',
			tableType: 'admin',
		},
		{
			data: 'admin_phone',
			header: 'Admin Phone',
			fieldId: 'profileFieldAdminPhone',
			tableType: 'admin',
		},
		{
			data: 'admin_fax',
			header: 'Admin Fax',
			fieldId: 'profileFieldAdminFax',
			tableType: 'admin',
		},

		{
			data: 'exec_title',
			header: 'Exec Title',
			fieldId: 'profileFieldExecTitle',
			tableType: 'exec',
		},
		{
			data: 'exec_first',
			header: 'Exec First Name',
			fieldId: 'profileFieldExecFirst',
			tableType: 'exec',
		},
		{
			data: 'exec_last',
			header: 'Exec Last Name',
			fieldId: 'profileFieldExecLast',
			tableType: 'exec',
		},
		{
			data: 'exec_email',
			header: 'Exec Email',
			fieldId: 'profileFieldExecEmail',
			tableType: 'exec',
		},
		{
			data: 'exec_position',
			header: 'Exec Position',
			fieldId: 'profileFieldExecPosition',
			tableType: 'exec',
		},
		{
			data: 'exec_phone',
			header: 'Exec Phone',
			fieldId: 'profileFieldExecPhone',
			tableType: 'exec',
		},
		{
			data: 'exec_fax',
			header: 'Exec Fax',
			fieldId: 'profileFieldExecFax',
			tableType: 'exec',
		},
		{
			data: 'exec_company',
			header: 'Exec Company',
			fieldId: 'profileFieldExecCompany',
			tableType: 'exec',
		},
		{
			data: 'exec_address1',
			header: 'Exec Address 1',
			fieldId: 'profileFieldExecAddress1',
			tableType: 'exec',
		},
		{
			data: 'exec_address2',
			header: 'Exec Address 2',
			fieldId: 'profileFieldExecAddress2',
			tableType: 'exec',
		},
		{
			data: 'exec_city',
			header: 'Exec City',
			fieldId: 'profileFieldExecCity',
			tableType: 'exec',
		},
		{
			data: 'exec_state',
			header: 'Exec State',
			fieldId: 'profileFieldExecState',
			tableType: 'exec',
		},
		{
			data: 'exec_zip',
			header: 'Exec Zip',
			fieldId: 'profileFieldExecZip',
			tableType: 'exec',
		},
	]
	const columnIndexes: {[index: string]: number} = columnsInfo.reduce((obj, col, index) => ({...obj, [col.data]: index}), {})
	const headers = columnsInfo.map((col) => col.header)
	const profilesDt = $('#profilesTable').tableHeaders(headers).DataTable({
		ajax: {
			url: '/tables/profiles',
			type: 'POST',
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
			},
			data: (postData: DataTables.AjaxDataRequest) => ({
				...postData,
				filters: getProfilesFilters(),
				fields: getProfileFields(),
				orderCols: postData.order.map(({column}) => columnsInfo[column].data),
			}),
		},
		scrollX: true,
		columns: columnsInfo,
		columnDefs: [{targets: '_all', defaultContent: ''}], 
		order: [[columnIndexes.client_name, 'ASC']],
		serverSide: true,
		responsive: {
			details: false,
		},
		language: {
			emptyTable: "No profiles to show",
			info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> profiles",
			infoEmpty: "Showing 0 to 0 of 0 profiles",
			infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total profiles)",
			lengthMenu: "Show _MENU_ profiles",
		},
		initComplete: () => {
			const $scroll = $('#profilesTableContainer').find('.dataTables_scroll')
			const $scrollBody = $scroll.find('.dataTables_scrollBody')
			$scrollBody.doubleScroll({
				$insertBefore: $scroll,
			})
		},
	})

	//Show/hide fields every draw
	profilesDt.on('draw', () => {
		const fields = (profilesDt.ajax.params() as any).fields as ReturnType<typeof getProfileFields>
		const columns = profilesDt.columns() as any
		let fieldsIndicies: number[] = []
		let hiddenIndicies: number[] = []
		columns.eq(0).each((index: number) => {
			const col = profilesDt.column(index)
			const dataSrc = col.dataSrc()
			if (typeof dataSrc !== 'string') return
			if (fields.hasOwnProperty(dataSrc)) fieldsIndicies.push(index)
			if (fields[dataSrc] === 0) hiddenIndicies.push(index)
		})

		profilesDt.columns(fieldsIndicies).visible(true)
		if (hiddenIndicies.length) profilesDt.columns(hiddenIndicies).visible(false)

		profilesDt.columns.adjust()

		$(window).trigger('resize.doubleScroll')
	})

	//Reload table when filters change
	$('#profilesFilters, #profilesFields').on('change', () => {
		profilesDt.ajax.reload(undefined, false)
	})

	return profilesDt
}

const initRates = async () => {
	const {cols} = await fetchPost('/tables/rates_reporting', {site: 1})

	//Init table
	const columnsInfo = [
		{
			data: 'rate_year',
			header: 'Year',
		},
		{
			data: 'rate_quarter',
			header: 'Quarter',
		},
		...cols,
	]
	const columnIndexes = columnsInfo.reduce((obj, col, index) => ({...obj, [col.data]: index}), {})
	const headers = columnsInfo.map((col) => col.header)
	const ratesDt = $('#ratesTable').tableHeaders(headers).DataTable({
		ajax: {
			url: '/tables/rates',
			type: 'POST',
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
			},
			data: (postData) => ({
				...postData,
				filters: {site: 1},
			}),
		},
		scrollX: true,
		columns: columnsInfo,
		columnDefs: [{targets: '_all', defaultContent: ''}], 
		order: [[columnIndexes.rate_year, 'ASC']],
		serverSide: true,
		responsive: {
			details: false,
		},
		language: {
			emptyTable: "No rates to show",
			info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> rates",
			infoEmpty: "Showing 0 to 0 of 0 rates",
			infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total rates)",
			lengthMenu: "Show _MENU_ rates",
		},
		initComplete: () => {
			const $scroll = $('#ratesTableContainer').find('.dataTables_scroll')
			const $scrollBody = $scroll.find('.dataTables_scrollBody')
			$scrollBody.doubleScroll({
				$insertBefore: $scroll,
			})
		},
	})

	return ratesDt
}

$(() => {

	$('[data-bs-toggle="tooltip"]').tooltip()
	
	$('.chosen').chosen({disable_search_threshold: 10})
	
	// initProfiles()
	// initRates()
	if ($('#payer_container').length) init_client()
	if ($('#clent_edit_container').length) initClientEdit()
	init_about()
	initSupport()
	initCalEdit()
	initDocuments()
	initUsers()
	initContent()
	if ($('#sign-in-container')) init_login()
	if ($('#mfa_form').length == 1) initMfa()
	if ($('#hist_container')) init_history()
	initAccount()

	$('.print_modal').off('click').on('click', (event) => {
		const $btn = $(event.target)
		const $tab = $btn.closest('.tab-pane')

		let config = {
			title: 'Print Event',
			callback: function() {
				let $tabClone = $tab.clone()
				$tabClone.find('.print_modal').remove() 
				return $tabClone
			}
		}
		print_this(config)
	})

})

function init_client () {

	$("#adjustment").click(function () {
		if($(this).is(":checked")) {
			$("#adjustment_container").collapse('show')
		} else {
			$("#adjustment_container").collapse('hide')
		}
	})

	//get program id from the url
	const prog_id = window.location.hash.slice(1)

	$("#assessment_save").on("click", async () => {

		const data = {
			client_status: $('#client_status').val(),
			client_siteid: prog_id,
			client_name: $('#client_name').val(),
			client_adress1: $('#client_adress1').val(),
			client_adress2: $('#client_adress2').val(),
			client_city: $('#client_city').val(),
			client_state: $('#client_state').val(),
			client_zip: $('#client_zip').val(),
			client_naic: $('#client_naic').val(),
			client_group: $('#client_group').val(),
			client_fein: $('#client_fein').val(),
			client_special: $('#client_special').is(':checked') ? 1:0
		}

		if (data.client_name === "" || 
		data.client_name == null ||
		data.client_adress1 === "" || 
		data.client_adress1 == null ||
		data.client_city === "" || 
		data.client_city == null ||
		data.client_state === "" || 
		data.client_state == null ||
		data.client_zip === "" || 
		data.client_zip == null ||
		data.client_fein === "" ||
		data.client_fein == null) {
			displayNotification("Please Fill out the required fields", "danger")
			return
		}

		const res = await fetchPost('/form/submit', {type: 'client_submit', data: data})
		if(res.rc == 'OK') {
			post('/ui/client_edit', {id: res.id})
			displayNotification("Client Saved Successfully", "success")
		} else {
			displayNotification("Error Saving this client", "danger")
		}

	})
}

function initClientEdit() {

	let client: any = {
		id: $('#client_id').val() ? +$('#client_id').val() : null,
	}

	async function initFiling (client) {
		
		interface Age {
			age_id: number | null
			age_name: string
			age_amount: number
		}
		const ageMap: Map<HTMLElement, Age> = new Map()

		const $ageTemplate = $('.age_template').remove()
		const addAge = (age: Age) => {
			const $age = $ageTemplate.clone()
			$age.find('.age').val(age.age_id)
			$age.find('.age_label').text(age.age_name)
			ageMap.set($age[0], age)
			return $age
		}

		const getAges = async() => {
			try{
				const res = await fetchPost('/data/submit', {type: 'get_ages'})	
				if (res.rc == "OK") return res.ages				
			} catch(e) {
				console.log(e)
			}
		}

		if(client) {
			let ages = await getAges()
			ages.forEach(age => {
				$('.age_container').append(addAge(age))
			})
		}

		const calcFiling = async(rateId) => {
			if(rateId) {
				try {
					const res = await fetchPost('/data/submit', {type: "calc_file", data: rateId})
					if(res.rc == "OK"){
						$('#filet_date').html(res.data.rate_duedate)
						$('#filet_child').html(parseInt(res.data.cost_amount)/100)
						$('#filet_adult').html()
						$('#filet_senior').html()
					} else {
						console.log('error retrieving the file info')
					}
				} catch(e) {
					console.log(e)
				}
			}
		}

		$("#covered_lives").click(function () {
			if($(this).is(":checked")) {
				$(".age_container").hide()
			} else {
				$(".age_container").show()
			}
		})

		$('#file_rate') //calculate table on every form change
		.off('change')
		.on('change', (event) => {
			const rate = $("#file_rate").val()
			calcFiling(rate)
		})

		$('#file_save')
		.off('click')
		.on('click', async (event) => {
			try {
				const ages: Age[] = []
				$('.age_template')
				.toArray()
				.forEach((element) => {
					const age = ageMap.get(element)
					console.log(age)
					if (!age) return
					const age_name = $(element).find('.age_label').text()
					const age_amount = $(element).find('.age').val()
					ages.push({ ...age, age_name, age_amount })
				})
				const postData = {
					// age_id: age ? age.age_id : null,
					client_id: client ? client.id : null,
					rate: $("#file_rate").val(),
					ages,
				}
				console.log(postData)
				const res = await fetchPost('/form/submit', {type: "save_file", data: postData})
				if(res.rc == "OK"){
					displayNotification("Filing Submitted Successfully", "success")
					//send back to public site
				} else {
					displayNotification("Error Saving File", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		})
	}	

	function initContact(client) {
		$("#admin_archive").hide()

		const initContDt = () => {
			const columnsInfo = [
				{
					data: 'cont_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'cont_role',
					header: 'role',
					visible: false,
				},
				{
					data: 'cont_first',
					header: 'First Name',
				},
				{
					data: 'cont_last',
					header: 'Last Name',
				},
				{
					data: 'cont_rolepretty',
					header: 'Role',
				},
				{
					data: 'cont_position',
					header: 'Position',
				},
				{
					data: 'cont_phone',
					header: 'Phone',
				},
				{
					data: 'cont_fax',
					header: 'Fax',
				},
				{
					data: 'cont_email',
					header: 'Email',
				},
				{
					data: 'cont_status',
					header: 'Status',
					visible: false,
				},
				{
					header: 'Actions',
					data: '',
					fieldId: 'userFieldActions',
					orderable:      false,
					searchable: 	false,
					defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
				},
			]
		
			const headers = columnsInfo.map((col) => col.header)
			const contDt = $('#adminTable').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/contact',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						client: client
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{targets: '_all', defaultContent: ''}], 
				order: [[0, 'DESC']],
				serverSide: true,
				responsive: {
					details: false,
				},
				language: {
					emptyTable: "No contacts to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> contacts",
					infoEmpty: "Showing 0 to 0 of 0 contacts",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total contact)",
					lengthMenu: "Show _MENU_ contact",
				},
				initComplete: () => {
					const $scroll = $('#adminTableContainer').find('.dataTables_scroll')
					const $scrollBody = $scroll.find('.dataTables_scrollBody')
					$scrollBody.doubleScroll({
						$insertBefore: $scroll,
					})
				},
			})
	
			$('#adminTabContainer').off("click").on('click', '.show-edit', ({target}) => {
				let row = contDt.row($(event.target).closest('tr')).data()
				contEdit(row)
			})
		
			return contDt
		}
		let contDt = initContDt()

		const clearForm = (cont) => {
			if(cont.cont_role == 0 || cont.role == 0) {
				$("#admin_form :input").val('')
				$("#cont_title").val('').trigger("chosen:updated")
				$("#admin_form").collapse('hide')
				$("#admin_insert").show()
				$("#admin_archive").hide()
				$(".admin_interact").hide()
			} else {
				$("#exec_form :input").val('')
				$("#title").val('').trigger("chosen:updated")
				$("#exec_form").collapse('hide')
				$("#exec_insert").show()
				$("#exec_archive").hide()
				$(".exec_interact").hide()
			}
		}
	
		$("#admin_insert").off("click",).on("click", () => {
			$("#admin_insert").hide()
			contEdit({cont_id:0, cont_role: 0})
		})
	
		$("#exec_insert").off("click",).on("click", () => {
			$("#exec_insert").hide()
			contEdit({cont_id:0, cont_role: 1})
		})
	
		const contEdit = (cont) => {	
			console.log(cont)
			let isInsert = cont.cont_id == 0
			if (isInsert) { //INSERT
	
				if(cont.cont_role == 0) {
					$("#admin_form").collapse('show')
					$(".admin_interact").show() //save, clear buttons	
	
					$("#admin_clear").off("click").on("click", () => {
						clearForm(cont)
					})
		
					$("#admin_save").off("click").on("click", async () => {
						console.log(client)
						let data = {
							cont_id: cont.cont_id,
							role: cont.cont_role,  
							client_id: client.id, 
							title: $('#cont_title').val(),
							first: $('#cont_fname').val(),
							last: $('#cont_lname').val(),
							position: $('#cont_position').val(),
							phone: $('#cont_phone').val(),
							fax: $('#cont_fax').val(),
							email: $('#cont_email').val(),
							company: $('#cont_company').val(),
							address1: $('#cont_adress1').val(),
							address2: $('#cont_adress2').val(),
							zip: $('#cont_zip').val(),
						}
						contSave(data)
					})
		
				} else {
					$("#exec_form").collapse('show')
					$(".exec_interact").show() //save, clear buttons
					
					$("#exec_clear").off("click").on("click", () => {
						clearForm(cont)
					})
		
					$("#exec_save").off("click").on("click", async () => {
						let data = {
							cont_id: cont.cont_id,
							role: cont.cont_role,  
							title: $('#title').val(),
							client_id: client.id,
							first: $('#fname').val(),
							last: $('#lname').val(),
							position: $('#position').val(),
							phone: $('#phone').val(),
							fax: $('#fax').val(),
							email: $('#email').val(),
							company: $('#company').val(),
							address1: $('#address1').val(),
							address2: $('#address2').val(),
							city: $('#city').val(),
							states: $('#states').val(),
							zip: $('#zip').val(),
						}	
						contSave(data)
					})
				}
	
			} else { //UPDATE
				
				if(cont.cont_role == 0) { //ADMIN
					$('a[href="#adminTabContent"]').tab('show') // show correct tab 
					$("#admin_form").collapse('show') //enable form 
					$(".admin_interact").show() //enable save , clear 
					$("#admin_archive").show() //enable archive button
					$("#admin_insert").hide() //hide insert 
	
					//map data to form
					$('#cont_fname').val(cont.cont_first)
					$('#cont_lname').val(cont.cont_last)
					$('#cont_position').val(cont.cont_position)
					$('#cont_phone').val(cont.cont_phone)
					$('#cont_fax').val(cont.cont_fax)
					$('#cont_email').val(cont.cont_email)
		
					//event handlers
					$("#admin_clear").off("click").on("click", () => {
						clearForm(cont)
					})
	
					$("#admin_archive").off("click").on("click", () => {
						contArchive(cont)
					})
		
					$("#admin_save").off("click").on("click", async () => {
						let data = {
							cont_id: cont.cont_id,
							role: 0, //admin 
							client_id: client.id, 
							title: $('#cont_title').val(),
							first: $('#cont_fname').val(),
							last: $('#cont_lname').val(),
							position: $('#cont_position').val(),
							phone: $('#cont_phone').val(),
							fax: $('#cont_fax').val(),
							email: $('#cont_email').val(),
						}
						contSave(data)
					})
		
				} else { // EXEC

					$('a[href="#executiveTabContent"]').tab('show') // show correct tab 
					$("#exec_form").collapse('show') //enable form 
					$(".exec_interact").show() //enable save , clear 
					$("#exec_archive").show() //enable archive button
					$("#exec_insert").hide() //hide insert 
		
					//map data to form
					$('#fname').val(cont.cont_first)
					$('#lname').val(cont.cont_last)
					$('#position').val(cont.cont_position)
					$('#phone').val(cont.cont_phone)
					$('#fax').val(cont.cont_fax)
					$('#email').val(cont.cont_email)
					$('#company').val(cont.cont_company)
					$('#address1').val(cont.cont_address1)
					$('#address2').val(cont.cont_address2)
					$('#city').val(cont.cont_city)
					$('#state').val(cont.cont_state).trigger("chosen:updated")
					$('#zip').val(cont.cont_zip)
		
					$("#exec_clear").off("click").on("click", () => {
						clearForm(cont)
					})
		
					$("#exec_save").off('click').on("click", () => {
						let data = {
							cont_id: cont.cont_id,
							title: $('#title').val(),
							role: 1, //exec
							client_id: client.id,
							first: $('#fname').val(),
							last: $('#lname').val(),
							position: $('#position').val(),
							phone: $('#phone').val(),
							fax: $('#fax').val(),
							email: $('#email').val(),
							company: $('#company').val(),
							address1: $('#address1').val(),
							address2: $('#address2').val(),
							city: $('#city').val(),
							state: $('#state').val(),
							zip: $('#zip').val(),
						}
						contSave(data)
					})
				} 
			}
		}
	
		const contArchive = async (cont) => {
			let postData = {
				id: cont.cont_id,
				role: cont.cont_role,
				type: 'cont_archive',
				status: cont.cont_status
			}
			const res = await fetchPost('/form/submit', {type: 'contact_archive', data: postData})
			if(res.rc === 'OK') {
				displayNotification("Contact Archived Successfully", "success")
				contDt.ajax.reload()
				clearForm(postData)
			} else {
				displayNotification("error archiving this contact", "danger")
			}
		}
	
		const contSave = async (postData) => {
			const res = await fetchPost('/form/submit', {type: 'contact_submit', data: postData})
			if(res.rc == 'OK') {
				displayNotification("Contact Saved Successfully", "success")
				contDt.ajax.reload(undefined, false)
				clearForm(postData)
			} else {
				displayNotification("error saving this contact", "danger")
			}		
		}
	}

	initContact(client)
	initFiling(client)
}

function init_about() {

	$(".nav-link").on("click", () => {
		setTimeout(() => {
			$(window).trigger('resize')
		  }, 200)
	})

	$("#staff_form").on("change", () => {
		if($("#staff_global").is(':checked')) {
			$("#staff_scope").prop('disabled', true).trigger("chosen:updated")
		} else {	
			$("#staff_scope").prop('disabled', false).trigger("chosen:updated")
		}				
	})
	$("#member_form").on("change", () => {
		if($("#memb_global").is(':checked')) {
			$("#memb_scope").prop('disabled', true).trigger("chosen:updated")
		} else {
			$("#memb_scope").prop('disabled', false).trigger("chosen:updated")
		}				
	})

	const getStaffFilters = () => ({
		status: $('#staff_filterstatus').val(),
		scope: $('#staff_filterscope').val()
	})
	setCollapse({$btn: $('#staff_filterscollapse'), $target: $('#staff_filters')})

	const getMemberFilters = () => ({
		status: $('#memb_filterstatus').val(),
		scope: $('#memb_filterscope').val()
	})
	setCollapse({$btn: $('#memb_filterscollapse'), $target: $('#member_filters')})

	const initStaffDt = () => {
		const columnsInfo = [
			{
				data: 'staff_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'staff_name',
				header: 'Name',
			},
			{
				data: 'staff_scope',
				header: 'Scope',
			},
			{
				data: 'staff_title',
				header: 'Title',
			},
			{
				data: 'staff_status',
				header: 'status',
				visible: false,
			},
			{
				data: 'staff_sort',
				header: 'Sort',
			},
			{
				data: 'staff_startpretty',
				header: 'Start',
			},
			{
				data: 'staff_endpretty',
				header: 'End',
			},
			{
				data: 'staff_statuspretty',
				header: 'Status',
			},
			{
				header: 'Actions',
				data: '',
				orderable:      false,
				searchable: 	false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
			},
		]
	
		const headers = columnsInfo.map((col) => col.header)
		const staffDt = $('#staff_table').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/staff',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getStaffFilters(),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{targets: '_all', defaultContent: ''}], 
			order: [[0, 'DESC']],
			serverSide: true,
			responsive: {
				details: false,
			},
			dom: datatablesButtonDom,
			buttons: [
				{
					text: '<i class="fas fa-plus me-2"></i>Add',
					className: 'btn btn-sm btn-success',
					action: () => staffEdit({staff_id:0}),
				}
			],
			language: {
				emptyTable: "No staff to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> staff members",
				infoEmpty: "Showing 0 to 0 of 0 staff",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total staff members)",
				lengthMenu: "Show _MENU_ staff",
			},
			initComplete: () => {
				const $scroll = $('#stafftable_container').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#stafftable_container').off('click').on('click', '.show-edit', ({target}) => {
			let row = staffDt.row($(event.target).closest('tr')).data() as Staff
			staffEdit(row)
		})

		//Reload table when filters change
		$('#staff_filters').on('change', () => {
			staffDt.ajax.reload(undefined, false)
		})
	
		return staffDt
	}
	let staffDt = initStaffDt()

	type Staff =  {
		staff_id: number
		staff_img: number
		staff_name: string
		staff_sort: string
		staff_description: string
		staff_status: number,
		staff_title: string
		staff_startdate: string
		staff_enddate: string
		img_originalname: string
		img_size: string
	}

	const clearStaff = () => {
		$("#staff_form :input").val('')
		$(".staff_form").hide()
		$(".staff_toggle").hide()
		$("#staff_new").show()
		$('#staff_form').collapse('hide')
		$("#staffphoto_container").hide()
		$("#staff_scope").val('').trigger("chosen:updated")
		$('#staff_global').prop('checked', false)
		$("#staff_scope").prop('disabled', false).trigger("chosen:updated")
	}

	$("#staff_new").off("click",).on("click", () => {
		$("#staff_new").hide()
		staffEdit({staff_id:0})
	})

	const staffEdit = async(staff:Partial<Staff>) => {
		console.log(staff)

		let isInsert = staff.staff_id == 0
		if(isInsert) { //INSERT

			$("#staff_cancel").show()
			$(".staff_form").show()
			$("#staff_save").show()
			$('#staff_form').collapse('show')

		} else { //UPDATE 
			let data = {type: "staff_scope", id: staff.staff_id}
			const {scope} = await fetchPost('/data/submit', data)
			scope.forEach(s => {s == 0 || s == '0' ? $('#staff_global').prop('checked', true) : $('#staff_global').prop('checked', false)})

			$("#staff_photo").hide()
			$("#staffphoto_container").show()
			$("#staff_new").hide()
			$("#staff_cancel").show()
			$("#staff_save").show()
			$(".staff_form").show()
			$('#staff_form').collapse('show')
			if(staff.staff_status == 99) { 
				$("#staff_archive").hide()
				$("#staff_reactivate").show()
			} else {
				$("#staff_archive").show()
				$("#staff_reactivate").hide()
			}
			$('#id').val(staff.staff_id)
			$("#staff_scope").val(scope).trigger("chosen:updated")
			$('#name').val(staff.staff_name)
			$('#sort').val(staff.staff_sort)
			$('#description').val(staff.staff_description)
			$('#title').val(staff.staff_title)
			$('#start').val(staff.staff_startdate)
			$('#end').val(staff.staff_enddate)

			$('#staffphoto_original').html(htmlEsc(staff.img_originalname))
			$('#staffphoto_size').html(htmlEsc(staff.img_size))

			$("#staffphoto_change").off("click").on("click", () => {
				$("#staffphoto_container").hide()
				$("#staff_photo").show()
			})

			//hide scope if global checked
			$("#staff_global").is(':checked') ? $("#staff_scope").prop('disabled', true).trigger("chosen:updated") : $("#staff_scope").prop('disabled', false).trigger("chosen:updated")
		}

		$("#staff_cancel").off("click").on("click", (e) => {
			clearStaff()
			e.preventDefault()
		})
		
		$(".staff_toggle").off("click").on("click", (e) => {
			staffArchive(staff)
			e.preventDefault()
		})

		$("#staff_save").off('click').on("click", (e) => {
			let postData = {
				id: staff.staff_id,
				img_id: staff.staff_img,
				scope: $('#staff_global').is(':checked') ? [0] : $('#staff_scope').val(),
				sort: $('#sort').val(),
				name: $('#name').val(),
				photo: $('#staff_photo').val(),
				description: $('#description').val(),
				title: $('#title').val(),
				start: $('#start').val(),
				end: $('#end').val(),
			}

			console.log(postData)

			if(postData.sort < 1  || postData.sort == "") { //sort starts at 1
				displayNotification('Please enter a sort greater than 0', 'danger')
				return
			} 
			staffSave(postData)
		})
	}

	const staffSave = async (postData) => {
		try {
			const formData = new FormData($('#staff_form').get(0) as HTMLFormElement)
			formData.append('data', JSON.stringify(postData))
			formData.append('type', 'save_staff')
			const res = await fetchPost('/marketing/submit', formData)
			if(res.rc === 'OK') {
				displayNotification("Staff Member Saved Successfully", "success")
				staffDt.ajax.reload(undefined, false)
				clearStaff()
			} else {
				displayNotification("error saving this staff member", "danger")
			}
		} catch(e) {
			console.log(e)
		}
	}

	const staffArchive = async (staff: Staff) => {
		let postData = {
			type: 'archive_staff',
			id: staff.staff_id,
			status: staff.staff_status,
		}
		try {
			const res = await fetchPost('/marketing/submit', postData)
			if(res.rc === 'OK') {
				displayNotification("Staff Status Changed Successfully", "success")
				staffDt.ajax.reload()
				clearStaff()
			} else {
				displayNotification("Error Changing Staff Status", "danger")
			}
		} catch (e) {
			console.error(e)
		}
	}

	function initMembersDt () {
		const columnsInfo = [
			{
				data: 'member_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'member_title',
				header: 'Title',
			},
			{
				data: 'member_name',
				header: 'Name',
			},
			{
				data: 'member_scope',
				header: 'Scope',
			},
			{
				data: 'member_status',
				header: 'status',
				visible: false,
			},
			{
				data: 'member_sort',
				header: 'Sort',
			},
			{
				data: 'member_startpretty',
				header: 'Start',
			},
			{
				data: 'member_endpretty',
				header: 'End',
			},
			{
				data: 'member_statuspretty',
				header: 'Status',
			},
			{
				header: 'Actions',
				data: '',
				orderable:      false,
				searchable: 	false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
			},
		]
	
		const headers = columnsInfo.map((col) => col.header)
		const memberDt = $('#member_table').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/member',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getMemberFilters(),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{targets: '_all', defaultContent: ''}], 
			order: [[0, 'DESC']],
			serverSide: true,
			responsive: {
				details: false,
			},
			dom: datatablesButtonDom,
			buttons: [
				{
					text: '<i class="fas fa-plus me-2"></i>Add',
					className: 'btn btn-sm btn-success',
					action: () => memberEdit({member_id:0}),
				}
			],
			language: {
				emptyTable: "No members to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> members",
				infoEmpty: "Showing 0 to 0 of 0 member",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total members)",
				lengthMenu: "Show _MENU_ members",
			},
			initComplete: () => {
				const $scroll = $('#memberTableContainer').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#membertable_container').on('click', '.show-edit', ({target}) => {
			let row = memberDt.row($(event.target).closest('tr')).data() as member
			memberEdit(row)
		})

		//Reload table when filters change
		$('#member_filters').on('change', () => {
			memberDt.ajax.reload(undefined, false)
		})
	
		return memberDt
	}
	let memberDt = initMembersDt()

	$("#member_insert").off("click").on("click", () => {
		memberEdit({member_id: 0})
	})

	const clearMember = () => {
		$("#member_form :input").val('')
		$(".member_form").hide()
		$(".memb_toggle").hide()
		$("#member_insert").show()
		$('#member_form').collapse('hide')
		$("#memb_scope").val('').trigger("chosen:updated")
		$('#memb_global').prop('checked', false)
		$("#memb_scope").prop('disabled', false).trigger("chosen:updated")
	}

	type member = {
		member_id: number,
		member_name: string,
		member_title: string,
		member_sort: number,
		member_status: number,
		member_description: string,
		member_effectivedate: string,
		member_enddate: string,
	}

	async function memberEdit (member: Partial<member>)  {		
		$("#member_insert").hide()

		let isInsert = member.member_id == 0
		if (isInsert) { //ADD

			$("#memb_cancel").show()
			$("#memb_update").show()
			$(".member_form").show()
			$('#member_form').collapse('show')

		} else { //UPDATE
			let postData = {type: "member_scope",id: member.member_id}
			const {scope} = await fetchPost('/data/submit', postData)
			scope.forEach(s => {s == 0 || s == '0' ? $('#memb_global').prop('checked', true) : $('#memb_global').prop('checked', false)})

			//hide scope if global checked
			$("#memb_global").is(':checked') ? $("#memb_scope").prop('disabled', true).trigger("chosen:updated") : $("#memb_scope").prop('disabled', false).trigger("chosen:updated")
	
			$("#memb_update").show()
			$(".member_form").show()
			$("#memb_cancel").show()
			$('#member_form').collapse('show')
			if(member.member_status == 99) { //change to constant
				$("#memb_archive").hide()
				$("#memb_reactivate").show()
			} else {
				$("#memb_archive").show()
				$("#memb_reactivate").hide()
			}
			$("#memb_scope").val(scope).trigger("chosen:updated")
			$('#memb_name').val(member.member_name)
			$('#memb_sort').val(member.member_sort)
			$('#memb_description').val(member.member_description)
			$('#memb_title').val(member.member_title)
			$('#memb_start').val(member.member_effectivedate) 
			$('#memb_end').val(member.member_enddate)
		}
		
		$(".memb_toggle")
			.off("click")
			.on("click", (e) => {
				e.preventDefault()
				memberToggle(member)
		})

		$("#memb_cancel")
			.off("click")
			.on("click", (e) => {
				e.preventDefault()
				clearMember()
		})

		$("#memb_update").off("click").on("click", (e) => {
			let data = {
				type: "save_member",
				id: member.member_id ,
				scope: $('#memb_global').is(':checked') ? [0] :  $('#memb_scope').val(),
				sort: $('#memb_sort').val(),
				name: $('#memb_name').val(),
				title: $('#memb_title').val(),
				start: $('#memb_start').val(),
				end: $('#memb_end').val(),
			}

			let currentDate = new Date()

			console.log(currentDate)

			if(data.sort < 1  || data.sort == "") { //sort starts at 1
				displayNotification('Please Enter a Valid Sort', 'danger')
				return
			} 

			// if(data.start < currentDate )

			memberSave(data)
			
		})
	}

	async function memberToggle(member: member) {
		let postData = {
			type: 'archive_member',
			id: member.member_id,
			status: member.member_status,
		}
		try {
			const res = await fetchPost('/marketing/submit', postData)
			if(res.rc === 'OK') {
				displayNotification("Member Archived Successfully", "success")
				memberDt.ajax.reload()
				clearMember()
			} else {
				displayNotification("Error archiving this council member", "danger")
			}
		} catch (e) {
			console.error(e)
		}
	}

	async function memberSave(postData) {
		try {
			const res = await fetchPost('/marketing/submit', postData)
			if(res.rc === 'OK') {
				displayNotification("Member Saved Successfully", "success")
				memberDt.ajax.reload(undefined, false)
				clearMember()
			} else {
				displayNotification("Error saving this member", "danger")
			}
		} catch(e) {
			console.error(e)
		}
	}
}

function initSupport() {
	questions()
	supportContent()

	function questions() {

		$("#quest_form").on("change", () => {
			if($("#quest_global").is(':checked')) {
				$("#quest_scope").prop('disabled', true).trigger('chosen:updated')
			} else {
				$("#quest_scope").prop('disabled', false).trigger('chosen:updated')
			}				
		})

		const getQuestFilters = () => ({
			status: $('#quest_filterstatus').val(),
			scope: $('#quest_filterscope').val()
		})
		setCollapse({$btn: $('#quest_filterscollapse'), $target: $('#quest_filters')})

		const initQuestDt = () => {
			const columnsInfo = [
				{
					data: 'quest_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'quest_typepretty',
					header: 'Type',
				},
				{
					data: 'quest_scope',
					header: 'Scope',
				},
				{
					data: 'sec_name',
					header: 'Section',
				},
				{
					data: 'quest_question',
					header: 'Question',
				},
				{
					data: 'quest_answer',
					header: 'Answer',
				},
				{
					data: 'quest_startpretty',
					header: 'Start',
				},
				{
					data: 'quest_endpretty',
					header: 'End',
				},
				{
					data: 'quest_statuspretty',
					header: 'Status',
				},
				{
					header: 'Actions',
					data: '',
					orderable:      false,
					searchable: 	false,
					defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
				},
			]
		
			const headers = columnsInfo.map((col) => col.header)
			const questDt = $('#quest_table').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/question',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getQuestFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{targets: '_all', defaultContent: ''}], 
				order: [[0, 'DESC']],
				serverSide: true,
				responsive: {
					details: false,
				},
				dom: datatablesButtonDom,
				buttons: [
					{
						text: '<i class="fas fa-plus me-2"></i>Add',
						className: 'btn btn-sm btn-success',
						action: () => questEdit({quest_id:0}),
					}
				],
				language: {
					emptyTable: "No question to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> questions",
					infoEmpty: "Showing 0 to 0 of 0 questions",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total questions)",
					lengthMenu: "Show _MENU_ questions",
				},
				initComplete: () => {
					const $scroll = $('#questtable_container').find('.dataTables_scroll')
					const $scrollBody = $scroll.find('.dataTables_scrollBody')
					$scrollBody.doubleScroll({
						$insertBefore: $scroll,
					})
				},
			})
	
			$('#questtable_container').on('click', '.show-edit', ({target}) => {
				let row = questDt.row($(event.target).closest('tr')).data() as question
				questEdit(row)
			})

			//Reload table when filters change
			$('#quest_filters').on('change', () => {
				questDt.ajax.reload(undefined, false)
			})
		
			return questDt
		}
		let questDt = initQuestDt()
	
		type question = {
			quest_id: number,
			quest_type: number,
			quest_section: number,
			quest_scope: number,
			quest_status: number,
			quest_question: string,
			quest_answer: string,
			quest_startdate: string,
			quest_enddate: string
		}
	
		$("#question_insert").on("click", () => {
			questEdit({quest_id: 0})
		})
	
		const clearQForm = () => {
			$("#quest_form :input").val('')
			$("#quest_scope").val('').trigger("chosen:updated")
			$(".quest_form").hide()
			$("#quest_form").collapse("hide")
			$(".quest_hide").hide()
			$("#question_insert").show()
			$('#quest_global').prop('checked', false)
			$("#quest_scope").prop('disabled', false).trigger("chosen:updated")
		}

		const questEdit = async(quest: Partial<question>) => {			
			$("#question_insert").hide()

			let isInsert = quest.quest_id == 0
			if (isInsert) { //ADD
	
				$("#quest_cancel").show()
				$("#quest_update").show()
				$(".quest_form").show()
				$("#quest_form").collapse("show")	
	
			} else { //UPDATE
				let postData = {type: "question_scope",id: quest.quest_id}
				const {scope} = await fetchPost('/data/submit', postData)
				scope.forEach(s => {s == 0 || s == '0' ? $('#quest_global').prop('checked', true) : $('#quest_global').prop('checked', false)})
	
				$("#quest_cancel").show()
				$("#quest_update").show()
				$(".quest_form").show()
				$("#quest_form").collapse("show")
				if(quest.quest_status == 99) { 
					$("#quest_archive").hide()
					$("#quest_reactivate").show()
				} else {
					$("#quest_archive").show()
					$("#quest_reactivate").hide()
				}
				$("#quest_scope").val(scope).trigger("chosen:updated")
				$('#quest_section').val(quest.quest_section).trigger("chosen:updated")
				$('#quest_type').val(quest.quest_type).trigger("chosen:updated")
				$('#quest_question').val(quest.quest_question)
				$('#quest_answer').val(quest.quest_answer)
				$('#quest_start').val(quest.quest_startdate)
				$('#quest_end').val(quest.quest_enddate)

				//hide scope if global checked
				$("#quest_global").is(':checked') ? $("#quest_scope").prop('disabled', true).trigger("chosen:updated") : $("#quest_scope").prop('disabled', false).trigger("chosen:updated")
			}
			
			$(".quest_toggle")
				.off("click")
				.on("click", (e) => {
					questToggle(quest)
					e.preventDefault()
				})
	
			$("#quest_cancel")
				.off("click")
				.on("click", (e) => {
					clearQForm()
					e.preventDefault()
				})
	
			$("#quest_update").off("click").on("click", (e) => {
				let postData = {
					type: "question_insert",
					id: quest.quest_id,
					qtype: $('#quest_type').val(),
					scope: $('#quest_global').is(':checked') ? [0] :  $('#quest_scope').val(),
					section: $('#quest_section').val(),
					question: $('#quest_question').val(),
					answer: $('#quest_answer').val(),
					start: $('#quest_start').val(),
					end: $('#quest_end').val(),
				}
				questSave(postData)
			})
		}
	
		const questSave = async (postData) => {
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if(res.rc === 'OK') {
					displayNotification("Question Saved Successfully", "success")
					questDt.ajax.reload()
					clearQForm()
				} else if (res.rc === 'NO_MATCH') {
					displayNotification("This question section cannot be added for that question type", "danger")
				} else {
					displayNotification("error saving this staff member", "danger")
				} 
			} catch(e) {
				console.log(e)
			}
		}
	
		const questToggle  = async (quest) => {
			let postData = {
				id: quest.quest_id,
				status: quest.quest_status,
				type: 'question_archive',
			}
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if(res.rc === 'OK') {
					displayNotification("Question Status Changed Successfully", "success")
					questDt.ajax.reload()
					clearQForm()
				} else {
					displayNotification("Error Changing Status", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}
	}

	function supportContent() {

		$("#qcont_form").on("change", () => {
			if($("#qcont_global").is(':checked')) {
				$("#qcont_scope").prop('disabled', true).trigger('chosen:updated')
			} else {
				$("#qcont_scope").prop('disabled', false).trigger('chosen:updated')
			}				
		})

		const getQcontentFilters = () => ({
			status: $('#qcont_filterstatus').val(),
			scope: $('#qcont_filterscope').val(),
		})
		setCollapse({$btn: $('#qcont_filterscollapse'), $target: $('#qcont_filters')})
	
		const initQcontDt = () => {
			const columnsInfo = [
				{
					data: 'sup_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'sup_header',
					header: 'Content Description',
				},
				{
					data: 'sup_scope',
					header: 'Scope',
				},
				{
					data: 'sup_phone',
					header: 'Phone',
				},
				{
					data: 'sup_fax',
					header: 'Fax',
				},
				{
					data: 'sup_address',
					header: 'Address',
				},
				{
					data: 'sup_statuspretty',
					header: 'Status',
				},
				{
					data: 'sup_faq_overview',
					header: 'faq overview',
					visible: false,
				},
				{
					header: 'Actions',
					data: '',
					orderable:      false,
					searchable: 	false,
					defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
				},
			]
		
			const headers = columnsInfo.map((col) => col.header)
			const qcontDt = $('#qcont_table').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/support',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getQcontentFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{targets: '_all', defaultContent: ''}], 
				order: [[0, 'DESC']],
				serverSide: true,
				responsive: {
					details: false,
				},
				dom: datatablesButtonDom,
				buttons: [
					{
						text: '<i class="fas fa-plus me-2"></i>Add',
						className: 'btn btn-sm btn-success',
						action: () => qcontEdit({sup_id:0}),
					}
				],
				language: {
					emptyTable: "No content to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> content",
					infoEmpty: "Showing 0 to 0 of 0 content",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total content)",
					lengthMenu: "Show _MENU_ content",
				},
				initComplete: () => {
					const $scroll = $('#qconttable_container').find('.dataTables_scroll')
					const $scrollBody = $scroll.find('.dataTables_scrollBody')
					$scrollBody.doubleScroll({
						$insertBefore: $scroll,
					})
				},
			})
	
			$('#qconttable_container').on('click', '.show-edit', ({target}) => {
				let row = qcontDt.row($(event.target).closest('tr')).data()
				qcontEdit(row)
			})

			//Reload table when filters change
			$('#qcont_filters').on('change', () => {
				qcontDt.ajax.reload(undefined, false)
			})

			return qcontDt
		}
		let qcontDt = initQcontDt()
	
		$("#qcont_insert").on("click", () => {
			qcontEdit({sup_id: 0})
		})
	
		const clearQForm = () => {
			$("#qcont_form :input").val('')
			$("#qcont_scope").val('').trigger("chosen:updated")
			$(".qcont_form").hide()
			$("#qcont_form").collapse("hide")
			$(".qcont_hide").hide()
			$("#qcont_insert").show()
			$('#qcont_global').prop('checked', false)
			$("#qcont_scope").prop('disabled', false).trigger("chosen:updated")
		}
	
		const qcontEdit = async(qcont) => {
			$("#qcont_insert").hide()

			let isInsert = qcont.sup_id == 0
			if (isInsert) { //ADD
				
				$("#qcont_cancel").show()
				$("#qcont_update").show()
				$(".qcont_form").show()
				$("#qcont_form").collapse("show")
	
			} else { //UPDATE
				let postData = {type: "qcont_scope", id: qcont.sup_id}
				const {scope} = await fetchPost('/data/submit', postData)
				scope.forEach(s => {s == 0 || s == '0' ? $('#qcont_global').prop('checked', true) : $('#qcont_global').prop('checked', false)})
				
				if(qcont.sup_status == 99) { 
					$("#qcont_archive").hide()
					$("#qcont_reactivate").show()
				} else {
					$("#qcont_archive").show()
					$("#qcont_reactivate").hide()
				}
				$("#qcont_cancel").show()
				$("#qcont_update").show()
				$(".qcont_form").show()
				$("#qcont_form").collapse("show")
				$("#qcont_scope").val(scope).trigger("chosen:updated")
				$('#qcont_phone').val(qcont.sup_phone)
				$('#qcont_fax').val(qcont.sup_fax)
				$('#qcont_address').val(qcont.sup_address)
				$('#qcont_overview').val(qcont.sup_faq_overview)
				$('#qcont_description').val(qcont.sup_header)

				//hide scope if global checked
				$("#qcont_global").is(':checked') ? $("#qcont_scope").prop('disabled', true).trigger("chosen:updated") : $("#qcont_scope").prop('disabled', false).trigger("chosen:updated")
			}
			
			$(".qcont_toggle")
				.off("click")
				.on("click", (e) => {
					qcontToggle(qcont)
					e.preventDefault()
				})
	
			$("#qcont_cancel")
				.off("click")
				.on("click", (e) => {
					clearQForm()
					e.preventDefault()
				})
	
			$("#qcont_update").off("click").on("click", (e) => {
				let postData = {
					type: "save_qcontent",
					id: qcont.sup_id,
					scope: $('#qcont_global').is(':checked') ? [0] :  $('#qcont_scope').val(),
					header: $('#qcont_description').val(),
					phone: $('#qcont_phone').val(),
					fax: $('#qcont_fax').val(),
					address: $('#qcont_address').val(),
					overview: $('#qcont_overview').val(),
				}
				qcontSave(postData)
			})
		}
	
		const qcontSave = async (postData) => {
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if(res.rc === 'OK') {
					displayNotification("Content Saved Successfully", "success")
					qcontDt.ajax.reload()
					clearQForm()
				} else if(res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				} 
				else {
					displayNotification("error saving this content", "danger")
				}
			} catch(e) {
				console.log(e)
			}
		}
	
		const qcontToggle  = async (qcont) => {
			let postData = {
				type: 'toggle_qcontent',
				id: qcont.sup_id,
				status: qcont.sup_status,
			}
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if(res.rc === 'OK') {
					displayNotification("Content Status Changed Successfully", "success")
					qcontDt.ajax.reload()
					clearQForm()
				} else if(res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				} else {
					displayNotification("Error Changing Status", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}
	}
}

function initCalEdit() {

	$("#cal_form").on("change", () => {
		if($("#cal_global").is(':checked')) {
			$("#cal_scope").prop('disabled', true).trigger('chosen:updated')
		} else {
			$("#cal_scope").prop('disabled', false).trigger('chosen:updated')		
		}				
	})


	const getCalFilters = () => ({
		status: $('#cal_filterstatus').val(),
		scope: $('#cal_filterscope').val(),
	})
	setCollapse({$btn: $('#cal_filterscollapse'), $target: $('#cal_filters')})

	const initCalDt = () => {
		const columnsInfo = [
			{
				data: 'cal_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'calt_name',
				header: 'Type',
			},
			{
				data: 'cal_scope',
				header: 'Scope',
			},
			{
				data: 'cal_title',
				header: 'Title',
			},
			{
				data: 'cal_datepretty',
				header: 'Date',
			},
			{
				data: 'cal_time',
				header: 'Time',
			},
			{
				data: 'cal_location',
				header: 'Location',
			},
			{
				data: 'cal_statuspretty',
				header: 'Status',
			},
			{
				header: 'Actions',
				data: '',
				orderable:      false,
				searchable: 	false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
			},
		]
	
		const headers = columnsInfo.map((col) => col.header)
		const calDt = $('#cal_table').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/cal_edit',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getCalFilters(),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{targets: '_all', defaultContent: ''}], 
			order: [[0, 'DESC']],
			serverSide: true,
			responsive: {
				details: false,
			},
			dom: datatablesButtonDom,
			buttons: [
				{
					text: '<i class="fas fa-plus me-2"></i>Add',
					className: 'btn btn-sm btn-success',
					action: () => calEdit({cal_id:0}),
				}
			],
			language: {
				emptyTable: "No event to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> events",
				infoEmpty: "Showing 0 to 0 of 0 event",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total events)",
				lengthMenu: "Show _MENU_ events",
			},
			initComplete: () => {
				const $scroll = $('#caledittable_container').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#caledittable_container').on('click', '.show-edit', ({target}) => {
			let row = calDt.row($(event.target).closest('tr')).data() as calEvent
			calEdit(row)
		})

		//Reload table when filters change
		$('#cal_filters').on('change', () => {
			calDt.ajax.reload(undefined, false)	
		})
	
		return calDt
	}
	let calDt = initCalDt()

	type calEvent = {
		cal_id: number,
		cal_type: number,
		cal_scope: number,
		cal_status: number,
		cal_title: string,
		cal_date: string,
		cal_time: string,
		cal_location: string,
		cal_agenda: string,
		cal_material: string,
		cal_minutes: string,
	}

	$("#cal_insert").on("click", () => {
		calEdit({cal_id: 0})
	})

	const clearCalForm = () => {
		$("#cal_form :input").val('')
		$("#cal_scope").val('').trigger("chosen:updated")
		$(".cal_form").hide()
		$("#cal_form").collapse("hide")
		$(".cal_hide").hide()
		$("#cal_insert").show()
		$('#cal_global').prop('checked', false)
		$("#cal_scope").prop('disabled', false).trigger("chosen:updated")
	}
	
	const getDocName = async(id) => {
		let postData = {type: "get_doc", id: id}
		const res = await fetchPost('/data/submit', postData)
		const name = res.name
		return name
	}

	const calEdit = async(calEvent: Partial<calEvent>) => {
		let isInsert = calEvent.cal_id == 0
		if (isInsert) { //ADD

			$("#cal_cancel").show()
			$("#cal_update").show()
			$(".cal_form").show()
			$(".cal_file").show()
			$("#cal_form").collapse("show")
			$("#calfile_container").hide()

		} else { //UPDATE
			let postData = {type: "cal_scope",id: calEvent.cal_id}
			const {scope} = await fetchPost('/data/submit', postData)
			scope.forEach(s => {s == 0 || s == '0' ? $('#cal_global').prop('checked', true) : $('#cal_global').prop('checked', false)})

			if(calEvent.cal_status == 99) { 
				$("#cal_archive").hide()
				$("#cal_reactivate").show()
			} else {
				$("#cal_archive").show()
				$("#cal_reactivate").hide()
			}
			$("#calfile_container").show()
			$(".cal_file").hide()
			$("#cal_cancel").show()
			$("#cal_update").show()
			$(".cal_form").show()
			$("#cal_form").collapse("show")
			$("#cal_scope").val(scope).trigger("chosen:updated")
			$('#cal_type').val(calEvent.cal_type).trigger("chosen:updated")
			$('#cal_title').val(calEvent.cal_title)
			$('#cal_date').val(calEvent.cal_date)
			$('#cal_time').val(calEvent.cal_time)
			$('#cal_location').val(calEvent.cal_location)

			//calfile table
			// const agendaName = await getDocName(calEvent.cal_agenda)
			// const materialName = await getDocName(calEvent.cal_agenda)
			// const minutesName = await getDocName(calEvent.cal_agenda)
			// console.log(agendaName)
			// $('#calfile_agenda').html(htmlEsc(agendaName))
			// $('#calfile_material').html(htmlEsc(materialName))
			// $('#calfile_minutes').html(htmlEsc(minutesName))

			//hide scope if global checked
			$("#cal_global").is(':checked') ? $("#cal_scope").prop('disabled', true).trigger('chosen:updated'): $("#cal_scope").prop('disabled', false).trigger('chosen:updated')
		}
		
		$(".cal_toggle")
			.off("click")
			.on("click", (e) => {
				calToggle(calEvent)
				e.preventDefault()
			})

		$("#cal_cancel")
			.off("click")
			.on("click", (e) => {
				clearCalForm()
				e.preventDefault()
			})

		$("#cal_update").off("click").on("click", (e) => {
			let postData = {
				type: "cal_save",
				id: calEvent.cal_id,
				cal_agenda: calEvent.cal_agenda,
				cal_minutes: calEvent.cal_minutes,
				cal_material: calEvent.cal_material,
				scope: $('#cal_global').is(':checked') ? [0] :  $('#cal_scope').val(),
				event_type: $('#cal_type').val(),
				title: $('#cal_title').val(),
				date: $('#cal_date').val(),
				time: $('#cal_time').val(),
				location: $('#cal_location').val(),
			}
			calSave(postData)
		})
	}

	const calSave = async (postData) => {
		try {
			const formData = new FormData($('#cal_form').get(0) as HTMLFormElement)
			formData.append('data', JSON.stringify(postData))
			formData.append('type', 'cal_save')
			const res = await fetchPost('/marketing/submit', formData)
			if(res.rc === 'OK') {
				displayNotification("Event Saved Successfully", "success")
				calDt.ajax.reload()
				clearCalForm()
			} else {
				displayNotification("error saving this event", "danger")
			}
		} catch(e) {
			console.log(e)
		}
	}

	const calToggle  = async (calEvent) => {
		let postData = {
			type: 'cal_archive',
			id: calEvent.cal_id,
			status: calEvent.cal_status,
		}
		try {
			const res = await fetchPost('/marketing/submit', postData)
			if(res.rc === 'OK') {
				displayNotification("Event Status Changed Successfully", "success")
				calDt.ajax.reload()
				clearCalForm()
			} else {
				displayNotification("Error Changing Status", "danger")
			}
		} catch (e) {
			console.log(e)
		}
	}
}

function initDocuments() {

	$("#doc_form").on("change", () => {
		if($("#doc_global").is(':checked')) {
			$('#doc_scope').prop('disabled', true).trigger("chosen:updated")
		} else {
			$('#doc_scope').prop('disabled', false).trigger("chosen:updated")
		}				
	})

	const getDocFilters = () => ({
		status: $('#doc_filterstatus').val(),
		scope: $('#doc_filterscope').val()
	})
	setCollapse({$btn: $('#doc_filterscollapse'), $target: $('#doc_filters')})

	const initDocDt = () => {
		const columnsInfo = [
			{
				data: 'doc_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'doc_original',
				header: 'Name',
			},
			{
				data: 'doc_scope',
				header: 'Scope',
			},
			{
				data: 'doct_name',
				header: 'Type',
			},
			{
				data: 'doc_startpretty',
				header: 'Start',
			},
			{
				data: 'doc_endpretty',
				header: 'End',
			},
			{
				data: 'doc_statuspretty',
				header: 'Status',
			},
			{
				header: 'Actions',
				data: '',
				orderable:      false,
				searchable: 	false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
			},
		]
	
		const headers = columnsInfo.map((col) => col.header)
		const docDt = $('#doc_table').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/documents',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getDocFilters(),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{targets: '_all', defaultContent: ''}], 
			order: [[0, 'DESC']],
			serverSide: true,
			responsive: {
				details: false,
			},
			dom: datatablesButtonDom,
			buttons: [
				{
					text: '<i class="fas fa-plus me-2"></i>Add',
					className: 'btn btn-sm btn-success',
					action: () => docEdit({doc_id:0}),
				}
			],
			language: {
				emptyTable: "No document to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> documents",
				infoEmpty: "Showing 0 to 0 of 0 document",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total documents)",
				lengthMenu: "Show _MENU_ documents",
			},
			initComplete: () => {
				const $scroll = $('#doctable_container').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		//Reload table when filters change
		$('#doc_filters').on('change', () => {
			docDt.ajax.reload(undefined, false)
		})

		$('#doctable_container').off('click').on('click', '.show-edit', ({target}) => {
			let row = docDt.row($(event.target).closest('tr')).data() as doc
			docEdit(row)
		})
	
		return docDt
	}
	let docDt = initDocDt()

	type doc = {
		doc_id: number,
		doc_type: number,
		doc_scope: number,
		doc_original: string,
		doc_doctid: number ,
		doc_status: number,
		doc_startdate: string,
		doc_enddate: string, 
		doct_name: string, 
		doc_link: string,
	}

	$("#doc_insert").on("click", () => {
		docEdit({doc_id: 0})
	})

	const clearDocForm = () => {
		$("#doc_form :input").val('')
		$(".doc_form").hide()
		$("#doc_form").collapse("hide")
		$(".hide").hide()
		$("#doc_insert").show()
		$("#doc_scope").val('').trigger("chosen:updated")
		$('#doc_global').prop('checked', false)
		$("#doc_scope").prop('disabled', false).trigger("chosen:updated")
	}

	const docEdit = async(doc: Partial<doc>) => {
		let isInsert = doc.doc_id == 0
		if (isInsert) { //ADD

			$("#doc_cancel").show()
			$("#doc_update").show()
			$(".doc_form").show()
			$("#doc_form").collapse("show")
			$("#doc_file").show()
			$("#docfile_container").hide()
			$("#doc_archive").hide()

		} else { //UPDATE
			let postData = {type: "doc_scope",id: doc.doc_id}
			const {scope} = await fetchPost('/data/submit', postData)
			scope.forEach(s => {s == 0 || s == '0' ? $('#doc_global').prop('checked', true) : $('#doc_global').prop('checked', false)})

			$(".doc_form").show()
			$("#doc_form").collapse("show")
			$("#doc_file").hide()
			$("#docfile_container").show()
			$("#doc_cancel").show()
			$("#doc_update").show()
			if(doc.doc_status == 99) { 
				$("#doc_archive").hide()
				$("#doc_reactivate").show()
			} else {
				$("#doc_archive").show()
				$("#doc_reactivate").hide()
			}
			$("#doc_scope").val(scope).trigger("chosen:updated")
			$('#doc_type').val(doc.doc_doctid).trigger("chosen:updated")
			$('#doc_start').val(doc.doc_startdate)
			$('#doc_end').val(doc.doc_enddate)
			//docfile table
			$('#docfile_original').html(htmlEsc(doc.doc_original))
			$('#docfile_type').html(htmlEsc(doc.doct_name))
			$('#docfile_link').html(htmlEsc(doc.doc_link))

			$("#docfile_change").off("click").on("click", () => {
				$("#docfile_container").hide()
				$("#doc_file").show()
			})

			//hide scope if global checked
			$("#doc_global").is(':checked') ? $('#doc_scope').prop('disabled', true).trigger("chosen:updated") : $('#doc_scope').prop('disabled', false).trigger("chosen:updated")
		}
		
		$(".doc_toggle")
			.off("click")
			.on("click", (e) => {
				docToggle(doc)
				e.preventDefault()
			})

		$("#doc_cancel")
			.off("click")
			.on("click", (e) => {
				clearDocForm()
				e.preventDefault()
			})

		$("#doc_update").off("click").on("click", (e) => {
			let postData = {
				id: doc.doc_id,
				scope: $('#doc_global').is(':checked') ? [0] :  $('#doc_scope').val(),
				type: $('#doc_type').val(),
				start: $('#doc_start').val(),
				end: $('#doc_end').val(),
			}
			docSave(postData)
		})	
	}

	const docSave = async (postData) => { 
		event.preventDefault()
		const formData = new FormData($('#doc_form').get(0) as HTMLFormElement)
		formData.append('data', JSON.stringify(postData))
		formData.append('type', 'doc_save')
		try {
			const res = await fetchPost('/marketing/submit', formData)
			if(res.rc === 'OK') {
				displayNotification("Document Saved Successfully", "success")
				docDt.ajax.reload()
				clearDocForm()
			} else {
				displayNotification("error saving this document", "danger")
			}
		} catch(e) {
			console.log(e)
		}
	}

	const docToggle  = async (doc) => {
		let postData = {
			id: doc.doc_id,
			status: doc.doc_status,
			type: 'doc_toggle',
		}
		try {
			const res = await fetchPost('/marketing/submit', postData)
			if(res.rc === 'OK') {
				displayNotification("Document Status Changed Successfully", "success")
				docDt.ajax.reload()
				clearDocForm()
			} else {
				displayNotification("Error Changing Status", "danger")
			}
		} catch (e) {
			console.log(e)
		}
	}

	const initDocHelpDt = () => {
		const columnsInfo = [
			{
				data: 'doc_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'doc_original',
				header: 'Name',
			},
			{
				data: 'doct_name',
				header: 'Type',
			},
			{
				data: 'doc_link',
				header: 'Link',
			},
		]
	
		const headers = columnsInfo.map((col) => col.header)
		$('.docinfo_table').each((_, element) => {
			const $table = $(element)
			const docDt = $table.tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/documents',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getDocFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				searching: false,
				columnDefs: [{targets: '_all', defaultContent: ''}], 
				order: [[0, 'DESC']],
				serverSide: true,
				responsive: {
					details: false,
				},
				language: {
					emptyTable: "No document to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> documents",
					infoEmpty: "Showing 0 to 0 of 0 document",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total documents)",
					lengthMenu: "Show _MENU_ documents",
				},
				deferLoading: 0,
			})
			$table.closest('.modal-body').find('.doc_tab').on('show.bs.tab', () => docDt.ajax.reload(null, false))
		})
	}

	initDocHelpDt()
}

function initContent() {
	payers()
	providers()
	home()
	prog()

	function payers() {

		$("#payers_form").on("change", () => {
			if($("#payers_global").is(':checked')) {
				$("#payers_scope").prop('disabled', true).trigger("chosen:updated")
			} else {
				$("#payers_scope").prop('disabled', false).trigger("chosen:updated")
			}				
		})

		const getPayerFilters = () => ({
			status: $('#payers_filterstatus').val(),
			scope: $('#payers_filterscope').val(),
		})
		setCollapse({$btn: $('#payers_filterscollapse'), $target: $('#payers_filters')})

		const initPayerDt = () => {
			const columnsInfo = [
				{
					data: 'payer_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'payer_overview',
					header: 'Overview',
					visible: false,
				},
				{
					data: 'payer_support',
					header: 'Support',
					visible: false,
				},
				{
					data: 'payer_heading',
					header: 'Content Description',
				},
				{
					data: 'payer_scope',
					header: 'Scope',
				},
				{
					data: 'payer_statuspretty',
					header: 'Status',
				},
				{
					header: 'Actions',
					data: '',
					orderable:      false,
					searchable: 	false,
					defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
				},
			]
		
			const headers = columnsInfo.map((col) => col.header)
			const payerDt = $('#payers_table').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/payers',
					type: 'POST',	
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getPayerFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{targets: '_all', defaultContent: ''}], 
				order: [[0, 'DESC']],
				serverSide: true,
				responsive: {
					details: false,
				},
				dom: datatablesButtonDom,
				buttons: [
					{
						text: '<i class="fas fa-plus me-2"></i>Add',
						className: 'btn btn-sm btn-success',
						action: () => payerEdit({payer_id:0}),
					}
				],
				language: {
					emptyTable: "No content to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> content",
					infoEmpty: "Showing 0 to 0 of 0 content",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total content)",
					lengthMenu: "Show _MENU_ content",
				},
				initComplete: () => {
					const $scroll = $('#payerstable_container').find('.dataTables_scroll')
					const $scrollBody = $scroll.find('.dataTables_scrollBody')
					$scrollBody.doubleScroll({
						$insertBefore: $scroll,
					})
				},
			})
	
			$('#payerstable_container').off('click').on('click', '.show-edit', ({target}) => {
				let row = payerDt.row($(event.target).closest('tr')).data()
				payerEdit(row)
			})

			//Reload table when filters change
			$('#payers_filters').on('change', () => {
				payerDt.ajax.reload(undefined, false)
			})
			
			return payerDt
		}
		let payerDt = initPayerDt()
	
		const clearPayerforn = () => {
			$("#payers_form :input").val('')
			$("#payers_scope").val('').trigger("chosen:updated")
			$(".hide").hide()
			$(".payers_form").hide()
			$(".payers_toggle").hide()
			$("#payers_form").collapse('hide')
			$("#payers_insert").show()
			$('#payers_global').prop('checked', false)
			$("#payersfile_container").hide()
			$("#payers_scope").prop('disabled', false).trigger("chosen:updated")
		}
	
		const payerEdit = async(payer) => {
			let isInsert = payer.payer_id == 0
			if (isInsert) { //ADD
	
				$("#payers_cancel").show()
				$("#payers_update").show()
				$(".payers_form").show()
				$("#payers_form").collapse('show')
	
			} else { //UPDATE
				let postData = {type: "payer_scope",id: payer.payer_id}
				const {scope} = await fetchPost('/data/submit', postData)
				scope.forEach(s => {s == 0 || s == '0' ? $('#payers_global').prop('checked', true) : $('#payers_global').prop('checked', false)})

				$("#payers_file").hide()
				$("#payersfile_container").show()
				$("#payers_cancel").show()
				$("#payers_update").show()
				$(".payers_form").show()
				$("#payers_form").collapse('show')
				if(payer.payer_status == 99) { 
					$("#payers_archive").hide()
					$("#payers_reactivate").show()
				} else {
					$("#payers_archive").show()
					$("#payers_reactivate").hide()
				}
				$("#payers_scope").val(scope).trigger("chosen:updated")
				$('#payers_heading').val(payer.payer_heading)
				$('#payers_overview').val(payer.payer_overview)
				$('#payers_support').val(payer.payer_support)
				$('#payers_assessment').val(payer.payer_assessment)

				$('#payersfile_original').html(htmlEsc(payer.img_originalname))

				$("#payersfile_change").off("click").on("click", () => {
					$("#payersfile_container").hide()
					$("#payers_file").show()
				})	

				//hide scope if global checked
				$("#payers_global").is(':checked') ? $("#payers_scope").prop('disabled', true).trigger("chosen:updated") : $("#payers_scope").prop('disabled', false).trigger("chosen:updated")
			}
			
			$(".payers_toggle")
				.off("click")
				.on("click", (e) => {
					payerToggle(payer)
				})
	
			$("#payers_cancel")
				.off("click")
				.on("click", (e) => {
					clearPayerforn()
				})
	
			$("#payers_update").off("click").on("click", (e) => {
				let postData = {
					id: payer.payer_id,
					img_id: payer.payer_img,
					scope: $('#payers_global').is(':checked') ? [0] :  $('#payers_scope').val(),
					header: $('#payers_heading').val(),
					file: $('#payers_file').val(),
					overview: $('#payers_overview').val(),
					assessment: $('#payers_assessment').val(),
					support: $('#payers_support').val(),
				}
				console.log(postData)
				payerSave(postData)
				e.preventDefault()
			})
		}
	
		const payerSave = async (postData) => {
			try {
				const formData = new FormData($('#payers_form').get(0) as HTMLFormElement)
				formData.append('data', JSON.stringify(postData))
				formData.append('type', 'save_payer')
				const res = await fetchPost('/marketing/submit', formData)
				if(res.rc === 'OK') {
					displayNotification("Content Saved Successfully", "success")
					payerDt.ajax.reload()
					clearPayerforn()
				} else if(res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				} else {
					displayNotification("error saving this staff member", "danger")
				}
			} catch(e) {
				console.log(e)
			}
		}
	
		const payerToggle  = async (payer) => {
			let postData = {
				type: 'toggle_payer',
				id: payer.payer_id,
				status: payer.payer_status,
			}
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if(res.rc === 'OK') {
					displayNotification("Content Status Changed Successfully", "success")
					payerDt.ajax.reload()
					clearPayerforn()
				} else if(res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				}
				else {
					displayNotification("Error Changing Status", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}
	}

	function providers() {

		$("#prov_form").on("change", () => {
			if($("#prov_global").is(':checked')) {
				$("#prov_scope").prop('disabled', true).trigger('chosen:updated')
			} else {
				$("#prov_scope").prop('disabled', false).trigger('chosen:updated')
			}				
		})

		const getProvFilters = () => ({
			status: $('#prov_filterstatus').val(),
			scope: $('#prov_filterscope').val(),
		})
		setCollapse({$btn: $('#prov_filterscollapse'), $target: $('#prov_filters')})

		const initProvDt = () => {
			const columnsInfo = [
				{
					data: 'prov_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'prov_header',
					header: 'Content Description',
				},
				{
					data: 'prov_scope',
					header: 'Scope',
				},
				{
					data: 'prov_statuspretty',
					header: 'Status',
				},
				{
					data: 'prov_description',
					header: 'Description',
					visible: false,
				},
				{
					header: 'Actions',
					data: '',
					orderable:      false,
					searchable: 	false,
					defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
				},
			]
		
			const headers = columnsInfo.map((col) => col.header)
			const provDt = $('#prov_table').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/providers',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getProvFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{targets: '_all', defaultContent: ''}], 
				order: [[0, 'DESC']],
				serverSide: true,
				responsive: {
					details: false,
				},
				dom: datatablesButtonDom,
				buttons: [
					{
						text: '<i class="fas fa-plus me-2"></i>Add',
						className: 'btn btn-sm btn-success',
						action: () => provEdit({prov_id:0}),
					}
				],
				language: {
					emptyTable: "No content to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> content",
					infoEmpty: "Showing 0 to 0 of 0 content",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total content)",
					lengthMenu: "Show _MENU_ content",
				},
				initComplete: () => {
					const $scroll = $('#provtable_container').find('.dataTables_scroll')
					const $scrollBody = $scroll.find('.dataTables_scrollBody')
					$scrollBody.doubleScroll({
						$insertBefore: $scroll,
					})
				},
			})
	
			$('#provtable_container').off('click').on('click', '.show-edit', ({target}) => {
				let row = provDt.row($(event.target).closest('tr')).data()
				provEdit(row)
			})

			//Reload table when filters change
			$('#prov_filters').on('change', () => {
				provDt.ajax.reload(undefined, false)
			})
		
			return provDt
		}
		let provDt  = initProvDt()
	
		const clearProvForm = () => {
			$("#prov_form :input").val('')
			$("#prov_scope").val('').trigger("chosen:updated")
			$(".prov_form").hide()
			$("#prov_form").collapse("hide")
			$(".prov_hide").hide()
			$("#prov_insert").show()
			$('#prov_global').prop('checked', false)
			$("#prov_scope").prop('disabled', false).trigger("chosen:updated")
		}
	
		const provEdit = async(prov) => {
			let isInsert = prov.prov_id == 0	
			if (isInsert) { //ADD
	
				$("#prov_cancel").show()
				$("#prov_update").show()
				$(".prov_form").show()
				$("#prov_form").collapse("show")
	
			} else { //UPDATE
				let postData = {type: "prov_scope", id: prov.prov_id}
				const {scope} = await fetchPost('/data/submit', postData)
				scope.forEach(s => {s == 0 || s == '0' ? $('#prov_global').prop('checked', true) : $('#prov_global').prop('checked', false)})
				
				if(prov.prov_status == 99) { 
					$("#prov_archive").hide()
					$("#prov_reactivate").show()
				} else {
					$("#prov_archive").show()
					$("#prov_reactivate").hide()
				}

				$("#prov_file").hide()
				$("#provfile_container").show()
				$("#prov_cancel").show()
				$("#prov_update").show()
				$(".prov_form").show()
				$("#prov_form").collapse("show")
				$("#prov_scope").val(scope).trigger("chosen:updated")
				$('#prov_header').val(prov.prov_header)
				$('#prov_description').val(prov.prov_description)

				$("#provfile_change").off("click").on("click", () => {
					$("#provfile_container").hide()
					$("#prov_file").show()
				})	

				$("#provfile_original").html(htmlEsc(prov.img_originalname))

				//hide scope if global checked
				$("#prov_global").is(':checked') ? $("#prov_scope").prop('disabled', true).trigger("chosen:updated") : $("#prov_scope").prop('disabled', false).trigger("chosen:updated")
			}
			
			$(".prov_toggle")
				.off("click")
				.on("click", (e) => {
					provToggle(prov)
					e.preventDefault()
				})
	
			$("#prov_cancel")
				.off("click")
				.on("click", (e) => {
					clearProvForm()
					e.preventDefault()
				})
	
			$("#prov_update").off("click").on("click", (e) => {
				let postData = {
					type: "save_prov",
					id: prov.prov_id,
					img_id: prov.prov_img,
					scope: $('#prov_global').is(':checked') ? [0] :  $('#prov_scope').val(),
					header: $('#prov_header').val(),
					description: $('#prov_description').val(),
				}
				provSave(postData)
				e.preventDefault()
			})
		}
	
		const provSave = async (postData) => {
			try {
				const formData = new FormData($('#prov_form').get(0) as HTMLFormElement)
				formData.append('data', JSON.stringify(postData))
				formData.append('type', 'save_prov')
				const res = await fetchPost('/marketing/submit', formData)
				if(res.rc === 'OK') {
					displayNotification("Content Saved Successfully", "success")
					provDt.ajax.reload()
					clearProvForm()
				} else if(res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				} else {
					displayNotification("error saving this staff member", "danger")
				}
			} catch(e) {
				console.log(e)
			}
		}
	
		const provToggle  = async (prov) => {
			let postData = {
				type: 'toggle_prov',
				id: prov.prov_id,
				status: prov.prov_status,
			}
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if(res.rc === 'OK') {
					displayNotification("Content Status Changed Successfully", "success")
					provDt.ajax.reload()
					clearProvForm()
				} else if(res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				}
				else {
					displayNotification("Error Changing Status", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}
	}

	function home() {

		$("#home_form").on("change", () => {
			if($("#home_global").is(':checked')) {
				$('#home_scope').prop('disabled', true).trigger('chosen:updated');
			} else {
				$('#home_scope').prop('disabled', false).trigger('chosen:updated');
			}				
		})

		const getHomeFilters = () => ({
			status: $('#home_filterstatus').val(),
			scope: $('#home_filterscope').val(),
		})
		setCollapse({$btn: $('#home_filterscollapse'), $target: $('#home_filters')})
	
		const initHomeDt = () => {
			const columnsInfo = [
				{
					data: 'home_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'home_scope',
					header: 'Scope',
				},
				{
					data: 'home_contdesc',
					header: 'Content Description',
				},
				{
					data: 'home_title',
					header: 'Title',
				},
				{
					data: 'home_startpretty',
					header: 'Start Date',
				},
				{
					data: 'home_endpretty',
					header: 'End Date',
				},
				{
					data: 'home_statuspretty',
					header: 'Status',
				},
				{
					data: 'home_description',
					header: 'Description',
					visible: false,
				},
				{
					data: 'home_about',
					header: 'About Overview',
					visible: false,
				},
				{
					data: 'home_member',
					header: 'Member Overview',
					visible: false,
				},
				{
					data: 'home_attributes',
					header: 'Attributes',
					visible: false,
				},
				{
					header: 'Actions',
					data: '',
					orderable:      false,
					searchable: 	false,
					defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
				},
			]
		
			const headers = columnsInfo.map((col) => col.header)
			const homeDt = $('#home_table').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/home',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getHomeFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{targets: '_all', defaultContent: ''}], 
				order: [[0, 'DESC']],
				serverSide: true,
				responsive: {
					details: false,
				},
				dom: datatablesButtonDom,
				buttons: [
					{
						text: '<i class="fas fa-plus me-2"></i>Add',
						className: 'btn btn-sm btn-success',
						action: () => homeEdit({home_id:0}),
					}
				],
				language: {
					emptyTable: "No program to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> program",
					infoEmpty: "Showing 0 to 0 of 0 programs",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total program)",
					lengthMenu: "Show _MENU_ programs",
				},
				initComplete: () => {
					const $scroll = $('#hometable_container').find('.dataTables_scroll')
					const $scrollBody = $scroll.find('.dataTables_scrollBody')
					$scrollBody.doubleScroll({
						$insertBefore: $scroll,
					})
				},
			})
	
			$('#hometable_container').off('click').on('click', '.show-edit', ({target}) => {
				let row = homeDt.row($(event.target).closest('tr')).data() 
				homeEdit(row)
			})

			//Reload table when filters change
			$('#home_filters').on('change', () => {
				homeDt.ajax.reload(undefined, false)
			})
	
			return homeDt
		}
		let homeDt = initHomeDt()
	
		const clearHomeForm = () => {
			$("#home_form :input").val('')
			$("#home_scope").val('').trigger("chosen:updated")
			$(".home_form").hide()
			$(".home_hide").hide()
			$("#home_insert").show()
			$("#home_form").collapse('hide')
			$('#home_global').prop('checked', false)
			$("#home_scope").prop('disabled', false).trigger("chosen:updated")
		}
	
		const homeEdit = async(home) => {
			let isInsert = home.home_id == 0	
			if (isInsert) { //ADD
	
				$("#home_cancel").show()
				$("#home_save").show()
				$(".home_form").show()
				$("#home_form").collapse('show')
	
			} else { //UPDATE
				let postData = {type: "home_scope", id: home.home_id}
				const {scope} = await fetchPost('/data/submit', postData)
				scope.forEach(s => {s == 0 || s == '0' ? $('#home_global').prop('checked', true) : $('#home_global').prop('checked', false)})

				if(home.home_status == 99) { 
					$("#home_archive").hide()
					$("#home_reactivate").show()
				} else {
					$("#home_archive").show()
					$("#home_reactivate").hide()
				}
				$("#home_cancel").show()
				$("#home_save").show()
				$(".home_form").show()
				$("#home_form").collapse('show')
				$("#home_scope").val(scope).trigger("chosen:updated")
				$('#home_contdesc').val(home.home_contdesc)
				$('#home_title').val(home.home_title)
				$('#home_start').val(home.home_start)
				$('#home_end').val(home.home_end)
				$('#home_attributes').val(home.home_attributes)
				$('#home_description').val(home.home_description)
				$('#home_about').val(home.home_about)
				$('#home_member').val(home.home_member)

				$("#home_aboutimg").hide()
				$("#home_backimg").hide()
				$("#homefile_container").show()


				$("#replace_home_about").off("click").on("click", (e) => {
					e.preventDefault()
					$("#provfile_container").hide()
					$("#home_aboutimg").show()
				})	

				$("#replace_background").off("click").on("click", (e) => {
					e.preventDefault()
					$("#provfile_container").hide()
					$("#home_backimg").show()
				})	

				$("#aboutfile_original").html(htmlEsc())
				$("#homefile_original").html(htmlEsc())

				//hide scope if global checked
				$("#home_global").is(':checked') ? $('#home_scope').prop('disabled', true).trigger('chosen:updated') :  $('#home_scope').prop('disabled', false).trigger('chosen:updated')
			}
			
			$(".home_toggle")
				.off("click")
				.on("click", (e) => {
					homeToggle(home)
					e.preventDefault()
				})
	
			$("#home_cancel")
				.off("click")
				.on("click", (e) => {
					clearHomeForm()
					e.preventDefault()
				})
	
			$("#home_save").off("click").on("click", (e) => {
				e.preventDefault()
				let postData = {
					id: home.home_id,
					img_about: home.home_about,
					img_background: home.home_background,
					file_type: UPLOAD_IMG,
					scope: $('#home_global').is(':checked') ? [0] : $('#home_scope').val(),
					cdescription: $('#home_contdesc').val(),
					title: $('#home_title').val(),
					start: $('#home_start').val(),
					end: $('#home_end').val(),
					attributes: $('#home_attributes').val(),
					description: $('#home_description').val(),
					about: $('#home_about').val(),
					member: $('#home_member').val(),
				}
				homeSave(postData)
			})
		}
	
		const homeSave = async (postData) => {
			try {
				const formData = new FormData($('#home_form').get(0) as HTMLFormElement)
				formData.append('data', JSON.stringify(postData))
				formData.append('type', 'save_home')
				const res = await fetchPost('/marketing/submit', formData)
				if(res.rc === 'OK') {``
					displayNotification("Content Saved Successfully", "success")
					homeDt.ajax.reload()
					clearHomeForm()
				}else if(res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				} 
				else {
					displayNotification("error saving this content", "danger")
				}
			} catch(e) {
				console.log(e)
			}
		}
	
		const homeToggle  = async (home) => {
			let postData = {
				type: 'toggle_home',
				id: home.home_id,
				status: home.home_status
			}
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if(res.rc === 'OK') {
					displayNotification("Status Changed Successfully", "success")
					homeDt.ajax.reload()
					clearHomeForm()
				} else if(res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				} else {
					displayNotification("Error Changing Status", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}
	}
	
	function prog() {
		const initprogDt = () => {
			const columnsInfo = [
				{
					data: 'prog_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'prog_name_long',
					header: 'Name',
				},
				{
					data: 'prog_name',
					header: 'Abbreviation',
				},
				{
					data: 'prog_taxid',
					header: 'Tax Id',
				},
				{
					data: 'prog_email',
					header: 'Email',
				},
				{
					header: 'Actions',
					data: '',
					orderable:      false,
					searchable: 	false,
					defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
				},
			]
		
			const headers = columnsInfo.map((col) => col.header)
			const progDt = $('#prog_table').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/programs',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{targets: '_all', defaultContent: ''}], 
				order: [[0, 'DESC']],
				serverSide: true,
				responsive: {
					details: false,
				},
				dom: datatablesButtonDom,
				buttons: [
					{
						text: '<i class="fas fa-plus me-2"></i>Add',
						className: 'btn btn-sm btn-success',
						action: () => progEdit({prog_id:0}),
					}
				],
				language: {
					emptyTable: "No program to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> program",
					infoEmpty: "Showing 0 to 0 of 0 programs",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total program)",
					lengthMenu: "Show _MENU_ programs",
				},
				initComplete: () => {
					const $scroll = $('#progtable_container').find('.dataTables_scroll')
					const $scrollBody = $scroll.find('.dataTables_scrollBody')
					$scrollBody.doubleScroll({
						$insertBefore: $scroll,
					})
				},
			})
	
			$('#progtable_container').on('click', '.show-edit', ({target}) => {
				let row = progDt.row($(event.target).closest('tr')).data() 
				progEdit(row)
			})
		
			return progDt
		}

		let progDt = initprogDt()
	
		const clearprogForm = () => {
			$("#prog_form :input").val('')
			$("#prog_scope").val('').trigger("chosen:updated")
			$(".prog_form").hide()
			$(".prog_hide").hide()
			$("#prog_insert").show()
			$("#prog_form").collapse('hide')
			$("#prog_logo").show()
			$("#proglogo_container").hide()
		}
	
		const progEdit = async(prog) => {
			let isInsert = prog.prog_id == 0
			if (isInsert) { //ADD
	
				$("#prog_cancel").show()
				$("#prog_save").show()
				$(".prog_form").show()
				$("#prog_form").collapse('show')
	
			} else { //UPDATE
				
				$("#prog_logo").hide()
				$("#proglogo_container").show()
				$("#prog_cancel").show()
				$("#prog_save").show()
				$(".prog_form").show()
				$("#prog_form").collapse('show')
				if(prog.prog_status == 99) { 
					$("#prog_archive").hide()
					$("#prog_reactivate").show()
				} else {
					$("#prog_archive").show()
					$("#prog_reactivate").hide()
				}
				$('#prog_namelong').val(prog.prog_name_long)
				$('#prog_nameshort').val(prog.prog_name)
				$('#prog_taxid').val(prog.prog_taxid)
				$('#prog_tax').val(prog.prog_taxid)
				$('#prog_email').val(prog.prog_email)

				//docfile table
				$('#proglogo_original').html(htmlEsc(prog.logo_original))
				$('#proglogo_size').html(htmlEsc(prog.logo_size))

				$("#proglogo_change").off("click").on("click", () => {
					$("#proglogo_container").hide()
					$("#prog_logo").show()
				})
			}
			
			$(".prog_toggle")
				.off("click")
				.on("click", (e) => {
					progToggle(prog)
					e.preventDefault()
				})
	
			$("#prog_cancel")
				.off("click")
				.on("click", (e) => {
					clearprogForm()
					e.preventDefault()
				})
	
			$("#prog_save").off("click").on("click", (e) => {
				e.preventDefault()
				let postData = {
					type: "save_prog",
					id: prog.prog_id,
					file_type: UPLOAD_IMG,
					name: $('#prog_nameshort').val(),
					name_long: $('#prog_namelong').val(),
					logo: $('#prog_logo').val(),
					tax: $('#prog_tax').val(),
					email: $('#prog_email').val(),
				}
				progSave(postData)
			})
		}
	
		const progSave = async (postData) => {
			try {
				const formData = new FormData($('#prog_form').get(0) as HTMLFormElement)
				formData.append('data', JSON.stringify(postData))
				formData.append('type', 'save_prog')
				const res = await fetchPost('/marketing/submit', formData)
				if(res.rc === 'OK') {
					displayNotification("Program Saved Successfully", "success")
					progDt.ajax.reload()
					clearprogForm()
				} else {
					displayNotification("error saving this program", "danger")
				}
			} catch(e) {
				console.log(e)
			}
		}
	
		const progToggle  = async (prog) => {
			let postData = {
				type: 'prog_toggle',
				id: prog.sup_id,
				status: prog.sup_id,
			}
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if(res.rc === 'OK') {
					displayNotification("Status Changed Successfully", "success")
					progDt.ajax.reload()
				} else {
					displayNotification("Error Changing Status", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}
	}
}

function initUsers() {

	$("#user_form").on("change", () => {
		if($("#user_super").is(':checked')){ //super user

			$("#user_role").prop('disabled', true).trigger('chosen:updated') 
			$("#user_program_access").prop('disabled', true).trigger('chosen:updated') 
			$("#user_programs_access").prop('disabled', true).trigger('chosen:updated') 
			$("#user_global").prop('disabled', true) 
			$("#user_global_warning").show() 

		} else { //regular
			$("#user_role").prop('disabled', false).trigger('chosen:updated') 
			$("#user_program_access").prop('disabled', false).trigger('chosen:updated') 
			$("#user_program_access").prop('disabled', false).trigger('chosen:updated') 
			$("#user_global").prop('disabled', false) 
			$("#user_global_warning").hide() 

			if($("#user_global").is(':checked')){ //regular - global access  
				$("#user_program_access").prop('disabled', true).trigger('chosen:updated') 
			} 
		}
	})

	const getUserFilters = () => ({
		status: $('#user_filterstatus').val(),
	})
	setCollapse({$btn: $('#user_filterscollapse'), $target: $('#user_filters')})
		
	const initUserDt = () => {
		const columnsInfo = [
			{
				data: 'user_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'role_pretty',
				header: 'Role',
			},
			{
				data: 'user_email',
				header: 'Email',
			},
			{
				data: 'user_first',
				header: 'First Name',
			},
			{
				data: 'user_last',
				header: 'Last Name',
			},
			{
				data: 'user_startpretty',
				header: 'Start',
			},
			{
				data: 'user_endpretty',
				header: 'End',
			},
			{
				data: 'user_statuspretty',
				header: 'Status',
			},
			{
				header: 'Actions',
				data: '',
				orderable:      false,
				searchable: 	false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
			},
		]
	
		const headers = columnsInfo.map((col) => col.header)
		const userDt = $('#user_table').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/users',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getUserFilters(),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{targets: '_all', defaultContent: ''}], 
			order: [[0, 'DESC']],
			serverSide: true,
			responsive: {
				details: false,
			},
			dom: datatablesButtonDom,
			buttons: [
				{
					text: '<i class="fas fa-plus me-2"></i>Add',
					className: 'btn btn-sm btn-success',
					action: () => userEdit({user_id:0}),
				}
			],
			language: {
				emptyTable: "No user to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> user",
				infoEmpty: "Showing 0 to 0 of 0 users",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total user)",
				lengthMenu: "Show _MENU_ users",
			},
			initComplete: () => {
				const $scroll = $('#usertable_container').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		//Reload table when filters change
		$('#user_filters').on('change', () => {
			userDt.ajax.reload(undefined, false)
		})

		$('#usertable_container').off('click').on('click', '.show-edit', ({target}) => {
			let row = userDt.row($(event.target).closest('tr')).data() 
			userEdit(row)
		})
	
		return userDt
	}
	let userDt = initUserDt()

	const clearuserForm = () => {
		$("#user_form :input").val('')
		$("#user_role").val('').trigger("chosen:updated")
		$("#user_program_access").val('').trigger("chosen:updated")
		$("#user_program_access").prop('disabled', false).trigger("chosen:updated")
		$("#user_state").val('').trigger("chosen:updated")
		$(".user_form").hide()
		$(".user_hide").hide()
		$("#user_insert").show()
		$("#user_form").collapse('hide')
		$('#user_global').prop('checked', false)
		$('#user_super').prop('checked', false)
		$("#user_global_warning").hide() 
	}

	const getPrograms = async(id) => {
		try {
			let data = {type: "access_user", id: id}
			const res = await fetchPost('/data/submit', data)
			console.log(res)
			if(res.global == 1) return -1
			return res.program
		} catch(e) {
			console.log(e)
		}
	}

	const showAuthorizations = async(user) => {
		$('#auth_container').show()

		//super user
		$('.auth_role').html(htmlEsc("Super User"))
		$('.auth_program_edit').html(htmlEsc(`MANAGE`))
		$('.auth_marketing').html(htmlEsc(`MANAGE`))
		$('.auth_filing').html(htmlEsc(`MANAGE`))
		$('.auth_accounting').html(htmlEsc(`MANAGE`))
		$('.auth_user').html(htmlEsc(`MANAGE`))
		$('.auth_programlist').html((`ALL`))

		//not global super user
		if(!user.user_super) {
			const getAuthorizations = async(id) => {
				try {
					let data = {type: "get_auth", id: id}
					const res = await fetchPost('/data/submit', data)
					return res
				} catch(e) {
					console.log(e)
				}
			}
			const res = await getAuthorizations(user.user_id)
			const auth = res.auth
			const programs = res.programs
			if (res.rc === "OK") {
				$('.auth_role').html(`${auth.role}`)
				$('.auth_programlist').html((`${programs}`))
				$('.auth_program_edit').html(htmlEsc(`${auth.program_edit}`))
				$('.auth_marketing').html(htmlEsc(`${auth.marketing}`))
				$('.auth_filing').html(htmlEsc(`${auth.filing}`))
				$('.auth_accounting').html(htmlEsc(`${auth.accounting}`))
				$('.auth_user').html(htmlEsc(auth.user))
			} else {
				console.log('error')
			}
		}
	}

	const userEdit = async(user) => {
		let isInsert = user.user_id == 0
		if (isInsert) { //ADD

			$('#auth_container').hide()
			$("#program_access_container").show()
			$("#user_cancel").show()
			$("#user_save").show()
			$(".user_form").show()
			$("#user_form").collapse('show')

		} else { //UPDATE
			let programData = await getPrograms(user.user_id)
			if(user.user_super == 0) { // not super user
				console.log('regular user')
				$("#user_super").prop('checked', false)
				$('#user_global').prop('checked', false)
				$('#user_global_warning').hide()
				$("#user_program_access").prop('disabled', false).trigger('chosen:updated') 
				$("#user_role").prop('disabled', false).trigger('chosen:updated')
				$("#user_global").prop('disabled', false)

				if(programData == -1) { //global accesss
					console.log('global access')
					$('#user_global').prop('checked', true)
					$("#user_program_access").prop('disabled', true).trigger('chosen:updated')
				} 

			} else { //super user
				console.log('super user')
				$('#user_super').prop('checked', true)
				$("#user_role").prop('disabled', true).trigger('chosen:updated') 
				$("#user_program_access").prop('disabled', true).trigger('chosen:updated') 
				$("#user_global").prop('disabled', true)
				$('#user_global_warning').show()
			}

			$("#user_cancel").show()
			$("#user_save").show()
			$(".user_form").show()
			$("#user_form").collapse('show')
			if(user.user_status == 99) { 
				$("#user_archive").hide()
				$("#user_reactivate").show()
			} else {
				$("#user_archive").show()
				$("#user_reactivate").hide()
			}
			$('#user_role').val(user.auth_role).trigger("chosen:updated")
			$('#user_program_access').val(programData).trigger("chosen:updated")
			$('#user_email').val(user.user_email)
			$('#user_password').val(user.user_password)
			$('#user_first').val(user.user_first)
			$('#user_last').val(user.user_last)
			$('#user_phone').val(user.user_phone)
			$('#user_address').val(user.user_address)
			$('#user_city').val(user.user_city)
			$('#user_state').val(user.user_state).trigger("chosen:updated")
			$('#user_zip').val(user.user_zip)
			$('#user_start').val(user.user_startdate)
			$('#user_end').val(user.user_enddate)

			if(user) showAuthorizations(user) // show authorization table
		}
		
		$(".user_toggle")
		.off("click")
		.on("click", (e) => {
			userToggle(user)
			e.preventDefault()
		})

		$("#user_cancel")
		.off("click")
		.on("click", (e) => {
			clearuserForm()
			e.preventDefault()
		})

		$("#user_save").off("click").on("click", (e) => {
			let postData = {
				type: 'save_user',
				data: {
					id: user.user_id,
					super: $("#user_super").is(':checked') ? 1 : 0,
					global: $("#user_global").is(':checked') ? 1 : 0,
					program_access: $('#user_super').is(':checked') || $('#user_global').is(':checked') ? null : $('#user_program_access').val(),
					password: $('#user_password').val(),
					role: $('#user_role').val(),
					email: $('#user_email').val(), 
					first: $('#user_first').val(),
					last: $('#user_last').val(),
					phone: $('#user_phone').val(),
					address: $('#user_address').val(),
					city: $('#user_city').val(),
					state: $('#user_state').val(),
					zip: $('#user_zip').val(),
					start: $('#user_start').val(),
					end: $('#user_end').val(),
				}
			}
			userSave(postData)
			e.preventDefault()
		})
	}

	const userSave = async (postData) => {
		try {
			const res = await fetchPost('/form/submit', postData)
			if(res.rc === 'OK') {
				displayNotification("User Saved Successfully", "success")
				userDt.ajax.reload()
				clearuserForm()
			} else {
				displayNotification("error saving this user", "danger")
			}
		} catch(e) {
			console.log(e)
		}
	}

	const userToggle  = async (user) => {
		let postData = {
			type: 'toggle_user',
			id: user.user_id,
			status: user.user_status
		}
		try {
			const res = await fetchPost('/marketing/submit', postData)
			if(res.rc === 'OK') {
				displayNotification("Status Changed Successfully ", "success")
				userDt.ajax.reload()
				clearuserForm()
			} else {
				displayNotification("Error Changing Status", "danger")
			}
		} catch (e) {
			console.log(e)
		}
	}
}

//login
if ($('#login_form').length == 1) {
	init_login()
}

function init_login() {

	localStorage.setItem('logout-event', 'logout' + Math.random()) // trigger logout for any other tabs that might still be open

	$('#login_form_submit').off('click').on('click',  async(e) => {
		let user = {
			email:  $('#login_form_email').val(),
			password:  $('#login_form_password').val(),
		}
		if(user.email == "" || user.password == "") {
			displayNotification('Please fill out all fields', "danger")
		}
		try {
			const res = await fetchPost('/login/auth', user)
			if(res.rc === 'OK') {
				window.location = res.redirect
			} else {
				displayNotification("Login Failed", "danger")
			}
		} catch (e) {
			console.error(e)
		}
		e.preventDefault()
	})
}

function init_history() {

	const getHistoryFilters = () => ({
		user: $('#hist_filterusers').val(),
		type: $('#hist_filtertype').val(),
	})
	setCollapse({$btn: $('#hist_filterscollapse'), $target: $('#hist_filters')})

	const initHistoryDt = () => {
		const columns = [
			{
				data: 'audit_id',
				visible: false,
			},
			{
				data: 'user_id',
				visible: false,
			},
			{
				data: 'user_email',
				header: 'User',
			},
			{
				data: 'user_name',
				header: 'Name',
			},
			{
				data: 'audit_type',
				header: 'Type',
			},
			{
				data: 'audit_action',
				header: 'Audit Action',
			},
			{
				data: 'audit_datepretty',
				header: 'Date',
			},
			{
				data: 'audit_data',
				header: 'Target',
			},
		]
	
		const headers = columns.map((col) => col.header)
		const historyDt = $('#history_table').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/history',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getHistoryFilters(),
				}),
			},
			scrollX: true,
			columns: columns,
			columnDefs: [{targets: '_all', defaultContent: ''}], 
			order: [[0, 'DESC']],
			serverSide: true,
			responsive: {
				details: false,
			},
			language: {
				emptyTable: "No value to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> values",
				infoEmpty: "Showing 0 to 0 of 0 value",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total values)",
				lengthMenu: "Show _MENU_ values",
			},
			initComplete: () => {
				const $scroll = $('#doctable_container').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		//Reload table when filters change
		$('#hist_filters').on('change', () => {
			historyDt.ajax.reload(undefined, false)
		})
	
		$('#historytable_container').off('click').on('click', '.show-view', ({target}) => {
			// let row = docDt.row($(event.target).closest('tr')).data() as doc
		})

		return historyDt
	}

	let historyDt = initHistoryDt()
}

const initMfa = () => {
	const user = JSON.parse(atob($('#mfa_form').data('params')))
	let selectedMethod = null

	const changeSelectedMethod = (method) => {
		selectedMethod = method
		switch (method) {
			case MFA_METHOD_EMAIL:
				$('#mfa_form_resend').show()
				$('#mfa_form_title').html(`Please enter the code sent to <b>${htmlEsc(user.email)}</b>.`)
				break
			case MFA_METHOD_SMS:
				$('#mfa_form_resend').show()
				$('#mfa_form_title').html(`Please enter the code sent to <b>${htmlEsc(user.phone)}</b>.`)
				break
			case MFA_METHOD_APP:
				$('#mfa_form_resend').hide()
				$('#mfa_form_title').html(`Please enter the code displayed on your authenticator app.`)
				break
		}
	}
	changeSelectedMethod(user.mfa_method)

	$('#mfa_form_submit').off('click').on('click', async (event) => {
		event.preventDefault()

		$('#mfa_form_submit').prop('disabled', true)

		try {
			const postData = {
				code: $('#mfa_form_code').val().toString(),
				method: selectedMethod,
				remember: $('#mfa_form_remember').is(':checked') ? 1 : 0,
			}

			await setTimeoutPromise(100)

			const res = await fetchPost('/login/mfa', postData)
			
			if (res.rc !== 'OK') throw res.rc
			
			window.location = res.redirect
		} catch (error) {
			switch (error) {
				case 'NO_MFA_LOGIN':
					window.location.href = ''
					break
				case 'INVALID_CODE':
					displayNotification('Incorrect code.', 'danger')
					break
				default:
					displayNotification('An error occured while logging in.', 'danger')
					console.error('mfa_form_submit', error)
			}
		}

		$('#mfa_form_submit').prop('disabled', false)
	})

	$('#mfa_form_resend').off('click').on('click', async (event) => {
		event.preventDefault()

		$('#mfa_form_resend').prop('disabled', true)

		try {
			await setTimeoutPromise(100)

			const res = await fetchPost('/login/send_mfa', {method: selectedMethod})
			
			if (res.rc !== 'OK') throw res.rc

			displayNotification('A new code has been sent.', 'success')
		} catch (error) {
			switch (error) {
				case 'NO_MFA_LOGIN':
					window.location.href = ''
					break
				default:
					displayNotification('An error occured while sending the code.', 'danger')
					console.error('mfa_form_resend', error)
			}
		}
		$('#mfa_form_resend').prop('disabled', false)
	})

	$('#mfa_form_change_method').off('click').on('click', () => {
		$('#mfa_method_send').off('click').on('click', async (event) => {
			event.preventDefault()

			const method = +$('#mfa_method_body').find('input[name="mfa_method"]:checked').val()
			if (method == selectedMethod || method == MFA_METHOD_APP) {
				if (method == MFA_METHOD_APP) changeSelectedMethod(method)
				$('#mfa_form_code').val('')
				$('#mfa_method_modal').modal('hide')
				return
			}
			console.log(method)

			$('#mfa_method_send').prop('disabled', true)

			try {
				await setTimeoutPromise(100)
	
				const res = await fetchPost('/login/send_mfa', {method})
				
				if (res.rc !== 'OK') throw res.rc
				changeSelectedMethod(method)
	
				displayNotification('A new code has been sent.', 'success')
				$('#mfa_method_modal').modal('hide')		
			} catch (error) {
				switch (error) {
					case 'NO_MFA_LOGIN':
						window.location.href = ''
						break
					default:
						displayNotification('An error occured while sending the code.', 'danger')
						console.error('mfa_form_resend', error)
				}
			}
	
			$('#mfa_method_send').prop('disabled', false)
		})
		$('#mfa_form_code').val('')
		$('#mfa_method_modal').modal('show')
	})
}

const initAccount = async() => {
	
	const clearGeneralSettings = () => {
		$("#settings_general_form :input").val('')
		$("#settingsModal").modal('hide')
	}

	const getPrograms = async(id) => {
		try {
			let data = {type: "access_user", id: id}
			const res = await fetchPost('/data/submit', data)
			if(res.global == 1) return -1
			return res.program
		} catch(e) {
			console.log(e)
		}
	}

	const getUser = async() => {
		try {
			const data = {type: 'get_user'}
			const {user} = await fetchPost('/data/submit', data)	
			return user
		} catch(e) {
			console.error(e)
		}
	}

	const userSettingsSave = async (postData) => {
		try {
			const res = await fetchPost('/form/submit', postData)
			if(res.rc === 'OK') {
				displayNotification("Settings Saved Successfully", "success")
				clearGeneralSettings()
			} else {
				displayNotification("Error Saving Settings", "danger")
			}
		} catch(e) {
			console.log(e)
		}
	}

	setCollapse({$btn: $('#acccount_form_appcollapse'), $target: $('#account_form_appconfig')})

	$("#account_form_mfa").on('click', () => {
		($('#account_form_mfa').is(':checked')) ? $('.auth_radio').prop('disabled', false) : 	$('.auth_radio').prop('disabled', true)
	})

	$('#account_form_authsave').off('click').on('click', async (event) => {
		event.preventDefault()

		let method = MFA_METHOD_EMAIL
		if($('#auth_email').is(':checked')) method =  MFA_METHOD_EMAIL
		if($('#auth_sms').is(':checked')) method =  MFA_METHOD_SMS
		if($('#auth_app').is(':checked')) method =  MFA_METHOD_APP
		const postData = {
			type: 'account_save',
			mfa: $('#account_form_mfa').length ? ($('#account_form_mfa').is(':checked') ? 1 : 0) : null,
			mfa_method: method,
		}

		const $btn = $(event.target)
		$btn.prop('disabled', true)
		$('#spinner').show()

		try {
			await setTimeoutPromise(100)

			const res = await fetchPost('/form/submit', postData)
			if (res.rc !== 'OK') throw res.rc
			$("#settingsModal").modal('hide') 
			$("#account_form_appconfig").collapse('hide')
			displayNotification('Account update successful.', 'success')

		} catch (error) {
			switch (error) {
				default:
					console.error('account submit', error)
					displayNotification('An error occurred while sending this request.', 'danger')
					break
			}
		}

		$('#spinner').hide()
		$btn.prop('disabled', false)
	})

	const checkQrCode = () => {
		if (!$('#account_mfa_app_qr_code_img').length) {
			$('#account_mfa_app_qr_code').append(`<img id="account_mfa_app_qr_code_img" src="/data/account_mfa_app_qr_code?t=${(new Date()).getTime()}" class="position-absolute" style="border-radius: 6px">`)
		}
	}

	$('#acccount_form_appcollapse').on('click', () => {
		checkQrCode()
	})
		
	$("#settings_dropdown").off('click').on('click', async() => {
		const user = await getUser()
		let programData = await getPrograms(user.user_id)

		$('#settings_email').val(user.user_email)
		$('#settings_first').val(user.user_first)
		$('#settings_last').val(user.user_last)
		$('#settings_password').val(user.password)
		$('#settings_phone').val(user.user_phone)
		$('#settings_address').val(user.user_address)
		$('#settings_city').val(user.user_city)
		$('#settings_state').val(user.user_state).trigger("chosen:updated")
		$('#settings_zip').val(user.user_zip)
		$('#settings_start').val(user.user_startdate)
		$('#settings_end').val(user.user_enddate)
		$('#settings_end').val(user.user_enddate)
		$('#settings_role').val(user.role).trigger("chosen:updated")
		$('#settings_program_access').val(programData).trigger("chosen:updated")

		let method = user.user_mfa_method
		switch (parseInt(method)) {
			case MFA_METHOD_EMAIL:
				$('#auth_email').prop('checked', true)
				$('#acccount_form_appcollapse').prop('disabled', true)
				break
			case MFA_METHOD_SMS:
				$('#auth_sms').prop('checked', true)
				$('#acccount_form_appcollapse').prop('disabled', true)
				break
			case MFA_METHOD_APP:
				$('#auth_app').prop('checked', true)
				$('#acccount_form_appcollapse').prop('disabled', false)
				break
		}

		$('.auth_radio').on('click', () => {
			method = $('#auth_type').find('input[name="auth_method"]:checked').val()
			if(parseInt(method) == MFA_METHOD_APP) {
				$('#acccount_form_appcollapse').prop('disabled', false)
				$("#account_form_appconfig").collapse('show')
				checkQrCode()
			} else {
				$('#acccount_form_appcollapse').prop('disabled', true) 
				$("#account_form_appconfig").collapse('hide')
			} 
		})

		$("#account_form_generalsave").off('click').on('click', async() => {
			let postData = {
				type: 'save_user',
				id: user.user_id,
				role: $("#settings_role").val(),
				email: $('#settings_email').val(), 
				first: $('#settings_first').val(),
				last: $('#settings_last').val(),
				password: $("#settings_password").val(),
				phone: $('#settings_phone').val(),
				address: $('#settings_address').val(),
				city: $('#settings_city').val(),
				state: $('#settings_state').val(),
				zip: $('#settings_zip').val(),
				start: $('#settings_start').val(),
				end: $('#settings_end').val(),	
			}
			userSettingsSave(postData)
		})
	})

	$('#account_mfa_app_qr_code_regenerate').off('click').on('click', async ({target}) => {
		const $btn = $(target)
		$btn.prop('disabled', true)

		try {
			$('#account_mfa_app_qr_code_img').remove()

			await setTimeoutPromise(10)

			const res = await fetchPost('/form/submit', {type: 'account_mfa_app_reset'})
			if (res.rc !== 'OK') throw res.rc

			checkQrCode()
		} catch (error) {
			console.error('account submit', error)
			displayNotification('An error occurred while sending this request.', 'danger')
		}

		$btn.prop('disabled', false)
	})
}